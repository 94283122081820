import { SearchInput, Text, Button, Icons } from 'components/ui';
import imgFaq from 'assets/images/faq.png';

import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { post } from 'config/api';
import { Spinner } from 'components/ui/Spinner';
import { Layout } from 'components/common';
import { Brief } from 'components/pages/appointment';

const Faq = () => {
    const [keyword, setKeyword] = useState('');

    const { data: faqs } = useQuery(['faqs', keyword], () =>
        post('/v1/faqs/page-view', { keyword }).then(res => res.data),
    );

    const keywordSubmitHandler = (e: any) => {
        e.preventDefault();
        setKeyword(e.target[0].value);
    };

    return (
        <Layout title='FAQ'>
            <Brief />
            <div className='container mt-5 lg:mt-0'>
                <div className='bg-white rounded-[30px] px-5 md:px-12 xl:px-24 py-7'>
                    <div className='flex justify-between bg-white rounded-[16px] lg:rounded-30px py-7'>
                        <div className='flex flex-col max-w-[554px]'>
                            <Text h1 styles='mt-auto'>
                                Frequenty <br />
                                Asked Questions
                            </Text>
                            <Text h3 styles='pt-5 '>
                                number porting. If you order a Sim Only
                                subscription with number portability, the SIM
                                card will be activated automatically. On the day
                                you s
                            </Text>
                            <div className='search mt-12 xl:w-[580px]'>
                                <SearchInput
                                    submitHandler={keywordSubmitHandler}
                                />
                            </div>
                        </div>
                        <div className='relative hidden lg:block'>
                            <img
                                className='block max-w-[350px] 2xl:max-w-[400px] animationOpacity'
                                src={imgFaq}
                                alt=''
                            />
                        </div>
                    </div>
                    {faqs?.data ? (
                        faqs?.data.map((item: any) => (
                            <div key={item.categoryId} className='mt-20 mb-10'>
                                <Text h2 styles='mb-8'>
                                    {item.categoryName}
                                </Text>
                                {item.faqs.map((faq: any) => (
                                    <Accordion key={faq.id}>
                                        <AccordionTitle>
                                            {faq.title}
                                        </AccordionTitle>
                                        <Item>{faq.description}</Item>
                                    </Accordion>
                                ))}
                            </div>
                        ))
                    ) : (
                        <div className='mt-10 flex flex-col items-center justify-center'>
                            <Spinner />
                            <p className='mt-5'>Loading...</p>
                        </div>
                    )}

                    <section className='bg-white rounded-[30px] px-5 lg:px-20 py-10 border border-primary'>
                        <div className='flex flex-col items-start'>
                            <Text h2 styles='mb-5'>
                                Do you need help or do you have another
                                question?
                            </Text>
                            <Text h3 styles='mb-3'>
                                We are happy to help you further.
                            </Text>
                            <Button
                                btnType='primary'
                                styles='mx-auto lg:ml-auto lg:mr-0 !px-10 mt-5'
                            >
                                <Icons massage className='hidden md:block' />
                                Start a converstion
                            </Button>
                        </div>
                    </section>
                </div>
            </div>
        </Layout>
    );
};

export default Faq;

var accordion = (e: any) => {
    var target = e.target as HTMLButtonElement;
    if (target.parentElement?.classList.contains('max-h-[66px]')) {
        target.classList.toggle('bg-gray-light');
        target.classList.toggle('text-primary');
        target.children[0].children[0].classList.toggle('hidden');
        target.children[0].children[1].classList.toggle('hidden');
        target.parentElement?.classList.remove('max-h-[66px]');
        target.parentElement?.classList.add('max-h-[1000px]');
        target.parentElement?.classList.add('shadow-box');
    } else {
        target.classList.toggle('bg-gray-light');
        target.classList.toggle('text-primary');
        target.children[0].children[0].classList.toggle('hidden');
        target.children[0].children[1].classList.toggle('hidden');
        target.parentElement?.classList.add('max-h-[66px]');
        target.parentElement?.classList.remove('max-h-[1000px]');
        target.parentElement?.classList.remove('shadow-box');
    }
};

interface ItemProps {}
export const Item: FC<ItemProps> = ({ children }) => {
    const [action, setAction] = useState('add');
    function handleAdd() {
        setAction('remove');
    }
    function handleRemove() {
        setAction('add');
    }
    return <div className='px-5 lg:px-8 pb-10 pt-2 text-left'>{children}</div>;
};

interface AccordionTitleProps {
    title?: string;
}
export const AccordionTitle: FC<AccordionTitleProps> = ({ children }) => {
    return (
        <button
            type='button'
            className='flex items-center justify-between w-full h-[66px] px-5 lg:px-8 duration-300 text-xs md:text-sm lg:text-base bg-gray-light text-left'
            onClick={accordion}
        >
            {children}
            <div className='w-5 ml-5'>
                <Icons arrowDown className=' w-4 h-4 lg:w-5' />
                <Icons arrowUp className='w-3 h-3 lg:w-4 lg:h-4 hidden' />
            </div>
        </button>
    );
};

interface AccordionProps {}
export const Accordion: FC<AccordionProps> = ({ children }) => {
    return (
        <div
            className={`bg-white rounded-[16px] md:rounded-20px overflow-hidden max-h-[66px] duration-300 mb-5`}
        >
            {children}
        </div>
    );
};
