import { Button, CheckBox, Icons, Input, Radio, Text } from 'components/ui';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Header } from './Header';
import { Toast } from './Toast';

interface IInformationProps {}

export const Information: FC<IInformationProps> = ({}) => {
    return (
        <>
            <div className='container mt-5 xl:px-10'>
                <Header step={2} />
            </div>

            <div className='container xl:px-10 mt-5 lg:mt-0'>
                <div className='flex md:flex-row flex-col items-start gap-x-5 py-5'>
                    {/* left column */}
                    <div className='md:w-2/3 flex flex-col'>
                        <div className='bg-white mb-10 rounded-[16px] lg:rounded-30px px-16 py-10'>
                            <Text p>I am a</Text>
                            <div className='mt-5'>
                                <label className='mr-10'>
                                    <Radio styles='mr-3' name='information' />
                                    Mr
                                </label>
                                <label>
                                    <Radio
                                        checked
                                        styles='mr-3'
                                        name='information'
                                    />
                                    Mrs
                                </label>
                                <form className='mt-10 grid md:grid-cols-2 grid-cols-1 gap-7'>
                                    {[
                                        'First name',
                                        'Last name',
                                        'E-mail address',
                                    ].map((item, index) => (
                                        <input
                                            type='text'
                                            placeholder={item}
                                            className='lg:rounded-[20px] rounded-[16px] bg-gray-light border-0 focus:outline-0 px-5 py-3 placeholder:text-xs lg:placeholder:text-sm focus:ring-0'
                                        />
                                    ))}
                                </form>
                                <div className='flex items-center gap-x-2 mt-7'>
                                    <CheckBox />
                                    <Text p>
                                        I have read and agree to the{' '}
                                        <Link
                                            to='condition'
                                            className='text-primary'
                                        >
                                            terms and conditions
                                        </Link>
                                        of the website
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* right column */}
                    <div className='md:w-1/3 flex flex-col'>
                        <div className='flex flex-col lg:px-5'>
                            <div className='flex flex-col px-6 lg:px-10 py-8 rounded-[16px] lg:rounded-[30px] bg-white'>
                                <div className='border-b-2'>
                                    <Text h2>Registration overview</Text>
                                    <Text h3 styles='mt-3'>
                                        Selected device
                                    </Text>
                                    <Text p styles='mb-5 mt-2 !text-sm'>
                                        iPhone 12 (A2403)
                                    </Text>
                                </div>

                                <div className='border-b-2'>
                                    <Text h3 styles='mt-4 mb-4'>
                                        repairs
                                        <a
                                            href='#'
                                            className='text-primary text-xs ml-2 hover:opacity-70 '
                                        >
                                            change
                                        </a>
                                    </Text>

                                    <Toast id={1} />
                                    <Toast id={2} />
                                </div>

                                <div className='border-b-2'>
                                    <div className='flex justify-between py-5'>
                                        <Text h3>Total</Text>
                                        <span className='price text-secondary'>
                                            219.99
                                            <span className='currency'>-</span>
                                        </span>
                                    </div>
                                </div>

                                <div className='flex justify-between py-5'>
                                    <Text h3>Repair time</Text>
                                    <Text p>240 minutes</Text>
                                </div>

                                <div className='flex flex-col py-4'>
                                    <Button btnType='secondary'>
                                        next step
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
