import { FC, HTMLAttributes } from 'react';
import { Icon } from '@iconify/react';

interface IText
    extends HTMLAttributes<HTMLHeadingElement | HTMLParagraphElement> {
    h1?: boolean;
    h2?: boolean;
    h3?: boolean;
    h4?: boolean;
    p?: boolean;
    styles?: string;
}

export const Text: FC<IText> = ({
    children,
    h1,
    h2,
    h3,
    h4,
    p,
    styles,
    ...props
}) => {
    const cH1 = ` text-[20px] font-semibold lg:text-4xl  font-primary text-dark-color lg:leading-60 ${styles}`;

    const cH2 = `text-lg lg:text-xl  font-semibold leading-7 font-primary text-dark-color ${styles}`;

    const cH3 = `lg:text-base  font-medium font-primary text-dark-color ${styles}`;

    const cH4 = `text-sm lg:text-base  font-medium font-primary text-dark-color ${styles}`;

    const cP = `font-regular text-xs lg:text-sm xl:text-base font-primary text-dark-color ${styles}`;

    return (
        <>
            {h1 ? (
                <h1 className={cH1} {...props}>
                    {children}
                </h1>
            ) : (
                ''
            )}

            {h2 ? (
                <h2 className={cH2} {...props}>
                    {children}
                </h2>
            ) : (
                ''
            )}

            {h3 ? (
                <h3 className={cH3} {...props}>
                    {children}
                </h3>
            ) : (
                ''
            )}
            {h4 ? (
                <h3 className={cH4} {...props}>
                    {children}
                </h3>
            ) : (
                ''
            )}
            {p ? (
                <p className={cP} {...props}>
                    {children}
                </p>
            ) : (
                ''
            )}
        </>
    );
};
