import React, { FC, HTMLAttributes } from 'react';

interface ICheckBox extends HTMLAttributes<HTMLInputElement> {
    cn?: string;
    required?: boolean;
}

export const CheckBox: FC<ICheckBox> = ({ cn, required, ...props }) => {
    cn = `border-2 border-[#DAEAFF] focus:ring-0 w-[14px] h-[14px] lg:w-[20px] lg:h-[20px]`;

    return (
        <input required={required} type='checkbox' className={cn} {...props} />
    );
};
