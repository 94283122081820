import React, { FC, PropsWithChildren } from 'react';

/* Components */
import { ErrorBoundary } from 'components/react';

/* Styles */
import 'styles/app.css';

/* Services */
import { HelmetService } from 'services/helmet';
import { RouterService } from 'services/router/RouterService';

type ServiceProvidersProps = PropsWithChildren<{}>;

const AppProviders: FC = ({ children }: ServiceProvidersProps) => {
    return (
        <React.StrictMode>
            <ErrorBoundary>
                <HelmetService>
                    <RouterService>{children}</RouterService>
                </HelmetService>
            </ErrorBoundary>
        </React.StrictMode>
    );
};

export default AppProviders;
