import { Button, Text } from 'components/ui';
import { useStoreOfferte } from 'hooks';
import { useProduct } from 'hooks/api';
import { FC, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    Container,
    LeftColumn,
    RightColumn,
    Section,
} from '../appointment/content';
import {
    DateTime,
    Overview,
    Repairs,
    RepairTime,
    Total,
} from '../appointment/content/rightColumn/index';
import { Toast } from './Toast';

export const Confirmation: FC<any> = props => {
    const { id: productId } = useParams();

    const { data: product } = useProduct(productId || '');
    const navigate = useNavigate();

    const store = useStoreOfferte(store => store);

    useEffect(() => {
        if (store.selectedRepairs.length === 0) {
            navigate('/selectDevice?type=offerte');
        }
        store.setOfferteStep(3);
    }, []);

    return (
        <>
            {/* infomation device */}
            <Container>
                <Section>
                    {/* left column */}
                    <LeftColumn>
                        <div className='bg-white mb-4 rounded-[16px] lg:rounded-30px px-10 py-8'>
                            <p className='xl:text-2xl lg:text-xl text-lg font-semibold mb-5'>
                                Billing information
                            </p>
                            <ul className='grid grid-cols-1 md:grid-cols-2'>
                                <li className='border-b py-5 '>
                                    <span className='mr-6 text-[#B9B9B9]'>
                                        Full Name
                                    </span>
                                    <span>{store.fullName}</span>
                                </li>
                                <li className='border-b py-5 '>
                                    <span className='mr-6 text-[#B9B9B9]'>
                                        Email
                                    </span>
                                    <span>{store.email}</span>
                                </li>

                                <li className='border-b py-5 '>
                                    <span className='mr-6 text-[#B9B9B9]'>
                                        Gender
                                    </span>
                                    <span>{store.gender}</span>
                                </li>
                            </ul>
                        </div>
                    </LeftColumn>

                    <RightColumn>
                        <div className='flex flex-col px-6 lg:px-10 py-8 rounded-[16px] lg:rounded-[30px] bg-white'>
                            <Overview>
                                {product?.data?.name} (
                                {product?.data?.description})
                            </Overview>

                            <Repairs>
                                {store.selectedRepairs.length > 0 ? (
                                    store.selectedRepairs.map((item: any) => (
                                        <Toast {...item} />
                                    ))
                                ) : (
                                    <div className='mb-4 text-sm'>
                                        No repairs selected
                                    </div>
                                )}
                            </Repairs>

                            <div className='border-b-2'>
                                <Total col>
                                    <span className='price text-secondary'>
                                        {store.totalPrice}
                                        <span className='currency'>,-</span>
                                    </span>
                                </Total>
                            </div>

                            <RepairTime col>
                                <span>{store.repairTime} minutes</span>
                            </RepairTime>

                            <div className='flex justify-center py-4'>
                                <Link to={`/offerte/confirmed/${productId}`}>
                                    <Button
                                        btnType='secondary'
                                        paddingBig
                                        styles={`mx-auto`}
                                    >
                                        Confirm
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </RightColumn>
                </Section>
            </Container>
            <br />
            <br />
            <br />
            <br />
        </>
    );
};
