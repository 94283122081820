import { useRepair } from 'hooks/api';
import { Delivered } from './Delivered';
import imgNotExist from 'assets/images/not-exist.svg';

export const Appointment = () => {
    const { data: personalRepair } = useRepair(1, 0);
    const { data: personalPayment } = useRepair(1, 1);
    const { data: personalDelivered } = useRepair(1, 2);

    console.log(personalRepair);
    const tabItems = [
        { title: 'Under repair', count: personalRepair?.data.length },
        { title: 'Awaiting Payment', count: personalPayment?.data.length },
        { title: 'Delivered', count: personalDelivered?.data.length },
    ];
    var handleTab = (e: any) => {
        document
            .querySelectorAll('#tab-container>div')
            .forEach((item, index) => {
                item.classList.add('hidden');
            });
        document.querySelectorAll('li[data-tab]').forEach((item, index) => {
            item.classList.remove('border-[#197BFF]');
        });
        document
            .querySelector(
                '#tab-container div[data-tab="' + e.target.dataset.tab + '"]',
            )
            ?.classList.remove('hidden');

        e.target.classList.add('border-[#197BFF]');
    };
    return (
        <div className='rounded-[16px] rounded-30px py-6'>
            <ul
                id='tabAppointment'
                className='flex text-center mb-5 overflow-x-scroll sm:overflow-x-auto'
            >
                {tabItems.map((item, index) => {
                    return (
                        <li
                            key={index}
                            className={`border-b-2 py-3 hover:text-primary cursor-pointer text-xs md:text-sm lg:text-base min-w-[145px] sm:w-full ${
                                index === 0 ? 'border-[#197BFF]' : ''
                            }`}
                            onClick={handleTab}
                            data-tab={index}
                        >
                            {item.title}
                            <span
                                className={`ml-2 inline-block bg-white py-1 px-2 rounded-full`}
                            >
                                {item.count}
                            </span>
                        </li>
                    );
                })}
            </ul>
            <div id='tab-container' className='h-full'>
                <div data-tab={0} className='h-full'>
                    {personalRepair?.data.length > 0 ? (
                        personalRepair?.data.map((item: any, index: number) => (
                            <Delivered key={index} {...item} />
                        ))
                    ) : (
                        <NoItem />
                    )}
                </div>
                <div data-tab={1} className='hidden h-full'>
                    {personalPayment?.data.length > 0 ? (
                        personalPayment?.data.map(
                            (item: any, index: number) => (
                                <Delivered key={index} {...item} />
                            ),
                        )
                    ) : (
                        <NoItem />
                    )}
                </div>
                <div data-tab={2} className='hidden h-full'>
                    {personalDelivered?.data.length > 0 ? (
                        personalDelivered?.data.map(
                            (item: any, index: number) => (
                                <Delivered key={index} {...item} />
                            ),
                        )
                    ) : (
                        <NoItem />
                    )}
                </div>
            </div>
        </div>
    );
};

const NoItem = () => {
    return (
        <div className='text-center h-full flex flex-col gap-y-5 py-20 justify-center items-center'>
            <img
                className='block w-[130px] xl:w-[170px]'
                src={imgNotExist}
                alt=''
            />
            <span className='font-medium xl:text-lg text-center'>
                there is no item to show
            </span>
        </div>
    );
};
