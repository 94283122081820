import { Button, CheckBox, Icons, Input, Radio, Text } from 'components/ui';
import { v4 as uuidv4 } from 'uuid';
import { useModal, useStoreAppointment } from 'hooks';

import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    useAddresses,
    useCreateAddress,
    useDeleteAddress,
    useUpdateAddress,
    useProduct,
} from 'hooks/api';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, LeftColumn, RightColumn, Section } from './content';
import {
    DateTime,
    Overview,
    Repairs,
    RepairTime,
    Total,
} from './content/rightColumn/index';
import { Toast } from './Toast';

export const Information: FC<any> = ({}) => {
    const [ut, setUt] = useState(0);
    const [checkRead, setCheckRead] = useState(false);

    const { id: productId } = useParams();
    const navigate = useNavigate();

    const { data: product } = useProduct(productId || '');
    const { data: addresses } = useAddresses();
    const deleteAddress = useDeleteAddress();
    const createAddress = useCreateAddress();
    const updateAddress = useUpdateAddress();
    const store: any = useStoreAppointment(store => store);
    const { Modal, setIsOpen, editable, setEditable } = useModal({
        title: 'Create Biling Information',
    });

    useEffect(() => {
        if (store.selectedRepairs.length === 0) {
            navigate('/selectDevice');
        }
        if (!localStorage.getItem('token')) {
            setIsOpen(true);
        }
        store.setAppointmentStep(3);
    }, []);

    const addressDeleteHandler = (id: any) => {
        if (!window.confirm('Are you sure you want to delete this item ?'))
            return;
        toast.promise(deleteAddress.mutateAsync(id), {
            pending: 'Please wait...',
            success: 'Deleted successfully',
            error: 'Something went wrong',
        });
    };

    return (
        <>
            <Container>
                <Section>
                    <LeftColumn>
                        <div className='mb-10'>
                            <Text p styles='mt-10 mb-6'>
                                <strong className='text-lg xl:text-3xl'>
                                    ‌‌Biling information
                                </strong>
                            </Text>
                            <button
                                type='button'
                                className='flex justify-center items-center rounded-16 lg:rounded-25 w-full h-[90px] lg:h-[105px] border border-primary text-primary font-semibold bg-gray-light mb-5 hover:opacity-70'
                                onClick={() => setIsOpen(true)}
                            >
                                <Icons
                                    add
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        marginRight: '10px',
                                    }}
                                />
                                Build new information
                            </button>
                            {!localStorage.getItem('token') &&
                                store.selectedBilling !== '' && (
                                    <div
                                        className='p-5 lg:p-8 bg-white rounded-[16px] lg:rounded-30px mb-4'
                                        id={store.selectedBilling.id}
                                    >
                                        <label className='flex items-center justify-between w-full'>
                                            <label className='flex items-center justify-center'>
                                                <Radio
                                                    lg
                                                    name='information'
                                                    onChange={() =>
                                                        store.setSelectedBilling(
                                                            store.selectedBilling,
                                                        )
                                                    }
                                                />
                                                <strong className='ml-5'>
                                                    {store.selectedBilling
                                                        .fullName
                                                        ? store.selectedBilling
                                                              .fullName
                                                        : '- - -'}
                                                </strong>
                                            </label>

                                            <div className='flex items-center space-x-5'>
                                                <button
                                                    type='button'
                                                    onClick={() => {
                                                        setIsOpen(true);
                                                        setEditable(
                                                            store.selectedBilling,
                                                        );
                                                    }}
                                                >
                                                    <Icons
                                                        edit
                                                        className='w-4 lg:w-5'
                                                    />
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        store.setSelectedBilling(
                                                            '',
                                                        );
                                                    }}
                                                    className='text-red-400 hover:text-red-800'
                                                >
                                                    <Icons close />
                                                </button>
                                            </div>
                                        </label>

                                        <div className='xl:ml-9 ml-8'>
                                            <Text p>
                                                {store.selectedBilling.city},{' '}
                                                {store.selectedBilling.address}
                                            </Text>
                                        </div>
                                    </div>
                                )}
                            {addresses?.data?.map((item: any, index: any) => (
                                <div
                                    key={index}
                                    className='p-5 lg:p-8 bg-white rounded-[16px] lg:rounded-25 mb-4'
                                    id={`alert-${index}`}
                                    role='alert'
                                >
                                    <label
                                        htmlFor={`btn-${index}`}
                                        className='flex items-center justify-between w-full'
                                    >
                                        <label className='flex items-center justify-center'>
                                            <Radio
                                                id={`btn-${index}`}
                                                lg
                                                name='information'
                                                onChange={() =>
                                                    store.setSelectedBilling(
                                                        item,
                                                    )
                                                }
                                            />
                                            <strong className='ml-5'>
                                                {item.fullName
                                                    ? item.fullName
                                                    : '- - -'}
                                            </strong>
                                        </label>

                                        <div className='flex items-center space-x-5'>
                                            <button
                                                type='button'
                                                onClick={() => {
                                                    setIsOpen(true);
                                                    setEditable(item);
                                                    console.log(item);
                                                }}
                                            >
                                                <Icons
                                                    edit
                                                    className='w-4 lg:w-5'
                                                />
                                            </button>
                                            <button
                                                onClick={() =>
                                                    addressDeleteHandler(
                                                        item.id,
                                                    )
                                                }
                                                className='text-red-400 hover:text-red-800'
                                            >
                                                <Icons close />
                                            </button>
                                        </div>
                                    </label>

                                    <div className='xl:ml-9 ml-8'>
                                        <Text p>
                                            {item?.city
                                                ? item?.city
                                                : store.selectedBilling.city}
                                            ,{' '}
                                            {item?.address
                                                ? item?.address
                                                : store.selectedBilling.address}
                                        </Text>
                                    </div>
                                </div>
                            ))}

                            <Modal className='max-w-xl'>
                                <div className='mb-5 text-base'>
                                    <Text p className='mr-8 mb-2'>
                                        I am
                                    </Text>
                                    <label className='mr-10'>
                                        <Radio
                                            name='information'
                                            styles='mr-3'
                                            onChange={() => setUt(0)}
                                            checked={ut === 0}
                                        />
                                        person
                                    </label>
                                    <label>
                                        <Radio
                                            name='information'
                                            styles='mr-3'
                                            onChange={() => setUt(1)}
                                            checked={ut === 1}
                                        />
                                        Cammercial
                                    </label>
                                </div>
                                <Formik
                                    initialValues={{
                                        fullName: editable?.fullName
                                            ? editable?.fullName
                                            : '',
                                        email: editable?.email
                                            ? editable?.email
                                            : '',
                                        phoneNumber: editable?.phoneNumber
                                            ? editable?.phoneNumber
                                            : '',
                                        city: editable?.city
                                            ? editable?.city
                                            : '',
                                        zipCode: editable?.zipCode
                                            ? editable?.zipCode
                                            : '',
                                        address: editable?.address
                                            ? editable?.address
                                            : '',
                                        companyName: editable?.companyName
                                            ? editable?.companyName
                                            : '',
                                        companyNumber: editable?.companyNumber
                                            ? editable?.companyNumber
                                            : '',
                                        vatNumber: editable?.vatNumber
                                            ? editable?.vatNumber
                                            : '',
                                    }}
                                    onSubmit={async (values: any) => {
                                        if (editable !== null) {
                                            if (localStorage.getItem('token')) {
                                                updateAddress
                                                    .mutateAsync(
                                                        editable?.id,
                                                        values,
                                                    )
                                                    .then(() => {
                                                        setIsOpen(false);
                                                    });
                                            } else {
                                                setIsOpen(false);
                                            }
                                        } else {
                                            if (localStorage.getItem('token')) {
                                                createAddress
                                                    .mutateAsync(values)
                                                    .then(() => {
                                                        setIsOpen(false);
                                                        setEditable(null);
                                                    });
                                            } else {
                                                store.setSelectedBilling(
                                                    values,
                                                );
                                                setIsOpen(false);
                                                setEditable(null);
                                            }
                                        }
                                    }}
                                    validationSchema={Yup.object().shape({
                                        fullName:
                                            Yup.string().required('Required'),
                                        city: Yup.string().required('Required'),
                                        zipCode:
                                            Yup.number().required('Required'),
                                        address:
                                            Yup.string().required('Required'),
                                        email: Yup.string()
                                            .email()
                                            .required('Required'),
                                        phoneNumber: Yup.number()
                                            .required('Required')
                                            .min(8),
                                        companyName: Yup.string(),
                                        companyNumber: Yup.string(),
                                        vatNumber: Yup.number(),
                                    })}
                                >
                                    {props => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            dirty,
                                            isSubmitting,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            handleReset,
                                        } = props;
                                        return (
                                            <form
                                                onSubmit={handleSubmit}
                                                className='space-y-5'
                                            >
                                                <input
                                                    type='hidden'
                                                    name='id'
                                                    value={
                                                        editable
                                                            ? editable.id
                                                            : uuidv4()
                                                    }
                                                />
                                                <div>
                                                    <Input
                                                        input
                                                        placeholder='Full Name'
                                                        name='fullName'
                                                        value={values.fullName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        styles={
                                                            errors.fullName &&
                                                            touched.fullName
                                                                ? 'text-red-400 border border-red-300'
                                                                : ''
                                                        }
                                                    />
                                                    {errors.fullName &&
                                                        touched.fullName && (
                                                            <div className='text-red-400 pl-4'>
                                                                {
                                                                    errors.fullName
                                                                }
                                                            </div>
                                                        )}
                                                </div>
                                                <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
                                                    <div>
                                                        <Input
                                                            input
                                                            placeholder='Email'
                                                            name='email'
                                                            value={values.email}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            styles={
                                                                errors.email &&
                                                                touched.email
                                                                    ? 'text-red-400 border border-red-300'
                                                                    : ''
                                                            }
                                                        />
                                                        {errors.email &&
                                                            touched.email && (
                                                                <div className='text-red-400 pl-4'>
                                                                    {
                                                                        errors.email
                                                                    }
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div>
                                                        <Input
                                                            input
                                                            placeholder='phone'
                                                            name='phoneNumber'
                                                            value={
                                                                values.phoneNumber
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            styles={
                                                                errors.phoneNumber &&
                                                                touched.phoneNumber
                                                                    ? 'text-red-400 border border-red-300'
                                                                    : ''
                                                            }
                                                        />
                                                        {errors.phoneNumber &&
                                                            touched.phoneNumber && (
                                                                <div className='text-red-400 pl-4'>
                                                                    {
                                                                        errors.phoneNumber
                                                                    }
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div>
                                                        <Input
                                                            input
                                                            placeholder='city'
                                                            name='city'
                                                            value={values.city}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            styles={
                                                                errors.city &&
                                                                touched.city
                                                                    ? 'text-red-400 border border-red-300'
                                                                    : ''
                                                            }
                                                        />
                                                        {errors.city &&
                                                            touched.city && (
                                                                <div className='text-red-400 pl-4'>
                                                                    {
                                                                        errors.city
                                                                    }
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div>
                                                        <Input
                                                            input
                                                            placeholder='postal code'
                                                            name='zipCode'
                                                            value={
                                                                values.zipCode
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            styles={
                                                                errors.zipCode &&
                                                                touched.zipCode
                                                                    ? 'text-red-400 border border-red-300'
                                                                    : ''
                                                            }
                                                        />
                                                        {errors.zipCode &&
                                                            touched.zipCode && (
                                                                <div className='text-red-400 pl-4'>
                                                                    {
                                                                        errors.zipCode
                                                                    }
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className='col-span-full'>
                                                        <Input
                                                            input
                                                            placeholder='street and house number'
                                                            name='address'
                                                            styles={
                                                                errors.address &&
                                                                touched.address
                                                                    ? 'text-red-400 border border-red-300'
                                                                    : ''
                                                            }
                                                            value={
                                                                values.address
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.address &&
                                                            touched.address && (
                                                                <div className='text-red-400 pl-4'>
                                                                    {
                                                                        errors.address
                                                                    }
                                                                </div>
                                                            )}
                                                    </div>
                                                    <Input
                                                        styles={`${
                                                            ut === 0
                                                                ? 'pointer-events-none opacity-50'
                                                                : ''
                                                        }`}
                                                        required={ut === 1}
                                                        input
                                                        placeholder='company name'
                                                        name='companyName'
                                                        value={
                                                            values.companyName
                                                        }
                                                    />
                                                    <Input
                                                        styles={`${
                                                            ut === 0
                                                                ? 'pointer-events-none opacity-50'
                                                                : ''
                                                        }`}
                                                        required={ut === 1}
                                                        input
                                                        placeholder='company number'
                                                        name='companyNumber'
                                                        value={
                                                            values.companyNumber
                                                        }
                                                    />
                                                    <Input
                                                        styles={`${
                                                            ut === 0
                                                                ? 'pointer-events-none opacity-50'
                                                                : ''
                                                        }`}
                                                        required={ut === 1}
                                                        input
                                                        placeholder='vat number'
                                                        name='vatNumber'
                                                        value={values.vatNumber}
                                                    />
                                                </div>

                                                {editable === null ? (
                                                    <Button
                                                        loading={
                                                            createAddress.isLoading
                                                        }
                                                        btnType='primary'
                                                        styles='w-full'
                                                    >
                                                        Confirm
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        loading={
                                                            updateAddress.isLoading
                                                        }
                                                        btnType='primary'
                                                        styles='w-full'
                                                    >
                                                        Update
                                                    </Button>
                                                )}
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </Modal>
                        </div>

                        <div>
                            <Text p>
                                <strong className='text-lg xl:text-3xl '>
                                    Notes
                                </strong>{' '}
                                <span className='text-xs xl:text-sm'>
                                    (optional)
                                </span>
                            </Text>
                            <Text p styles='xl:mt-2'>
                                Do you still need a case or screen protector?
                                Let us know and we will contact you about the
                                possibilities.
                            </Text>
                            <textarea
                                placeholder='Enter your notes here...'
                                className='border-0 w-full mt-4 lg:mt-7 p-5 xl:px-10 xl:py-5 rounded-[16px] lg:rounded-25 placeholder:text-dark placeholder:text-sm xl:placeholder:text-base'
                                name='note'
                                id='note'
                                rows={5}
                                onChange={(e: any) =>
                                    store.setNote(e.target.value)
                                }
                            />
                        </div>

                        <div className=' flex items-start my-3 '>
                            <label htmlFor='term' className='mt-2'>
                                <CheckBox
                                    id='term'
                                    onChange={(e: any) =>
                                        setCheckRead(e.target.checked)
                                    }
                                />
                            </label>
                            <Text p styles='ml-3 xl:text-xl'>
                                I have read and agree to the{' '}
                                <a href='#' className='text-primary'>
                                    terms and conditions{' '}
                                </a>
                                of the website
                            </Text>
                        </div>
                    </LeftColumn>

                    <RightColumn>
                        <div className='flex flex-col px-6 lg:px-10 py-8 rounded-[16px] lg:rounded-[30px] bg-white'>
                            <Overview>
                                {product?.data?.name} (
                                {product?.data?.description})
                            </Overview>

                            <Repairs>
                                {store.selectedRepairs.length > 0 ? (
                                    store.selectedRepairs.map(
                                        (item: any, index: any) => (
                                            <Toast key={index} {...item} />
                                        ),
                                    )
                                ) : (
                                    <div className='mb-4 text-sm'>
                                        No repairs selected
                                    </div>
                                )}
                            </Repairs>

                            <div className='border-b-2'>
                                <Total col>
                                    <span className='price text-secondary'>
                                        {store.totalPrice}
                                        <span className='currency'>
                                            {store.currency}
                                        </span>
                                    </span>
                                </Total>
                            </div>

                            <RepairTime col>
                                <span>{store.repairTime} minutes</span>
                            </RepairTime>

                            {store.deliveryMode === 1 && (
                                <DateTime col>
                                    <span>
                                        {new Date(
                                            store.selectedDate,
                                        ).toLocaleString()}
                                    </span>
                                </DateTime>
                            )}

                            <div className='flex justify-center py-4'>
                                <Link
                                    to={`/appointment/confirmation/${productId}`}
                                    className={`w-full ${
                                        checkRead ? '' : 'pointer-events-none'
                                    } ${
                                        store.selectedBilling !== ''
                                            ? ''
                                            : 'pointer-events-none'
                                    }`}
                                >
                                    <Button
                                        btnType={`${
                                            store.selectedBilling !== '' &&
                                            checkRead
                                                ? 'secondary'
                                                : 'disable'
                                        }`}
                                        paddingBig
                                        styles={`mx-auto ${
                                            checkRead
                                                ? ''
                                                : 'pointer-events-none'
                                        } ${
                                            store.selectedBilling !== ''
                                                ? ''
                                                : 'pointer-events-none'
                                        }`}
                                    >
                                        Next Step
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </RightColumn>
                </Section>
            </Container>
        </>
    );
};
