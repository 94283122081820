import { get, post } from 'config/api';
import { useQuery, useMutation } from 'react-query';

const problems = {
    getById: (id: any) =>
        get(`/v1/problems/anonymous-byproductid/${id}`).then(res => res.data),
};

export const useProblemByPId = (id: any) => {
    return useQuery(['product-problem', id], () => problems.getById(id), {
        enabled: id !== null,
    });
};
