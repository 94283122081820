import { render } from 'react-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import App from './App';
import { AuthProvider } from 'hooks';

/* services */
import reportWebVitals from 'services/webVitals/reportWebVitals';
import AppProviders from 'services';

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: twentyFourHoursInMs,
        },
    },
});

render(
    <QueryClientProvider client={queryClient}>
        <AppProviders>
            <AuthProvider>
                <App />
            </AuthProvider>
        </AppProviders>
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>,
    document.getElementById('root'),
);

reportWebVitals();
