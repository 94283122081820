import { FC } from 'react';
import { Input, Icons } from 'components/ui';
import logoFooter from '../assets/images/Mobile fabriq.svg';
import { Link } from 'react-router-dom';

const links = [
    { path: '/selectDevice', name: 'Repair & Price' },
    { path: '/selectDevice?type=offerte', name: 'Request quote' },
    { path: '/contactUs', name: 'Contact Us' },
    { path: '/faq', name: 'FAQ' },
    { path: '/', name: 'Cammercial' },
    { path: '/followUp', name: 'Follow up' },
];
const linkEmail = { path: 'mailto:', name: 'e0000m900@email.com' };
const linkPhone = { path: 'tel:6376478382748', name: '6376478382748' };

const Footer: FC = () => {
    return (
        <footer className='relative bg-[#003b71] pb-12'>
            <div className='container xl:px-20'>
                <div className='flex'>
                    <div className='logo my-6 lg:my-12'>
                        <img
                            className='w-36 lg:w-auto'
                            src={logoFooter}
                            alt=''
                        />
                    </div>
                </div>
                <div className='flex flex-wrap flex-col lg:flex-row'>
                    <div className='lg:w-8/12 contact'>
                        <div className='flex flex-col md:flex-row'>
                            <div className='md:w-1/3 hidden md:block'>
                                <ul className='flex flex-col gap-y-[14px]'>
                                    {links.map((link, index) => {
                                        return (
                                            <li key={index}>
                                                <Link
                                                    to={link.path}
                                                    className='text-white hover:text-primary duration-300'
                                                >
                                                    {link.name}
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className='md:w-2/3'>
                                <div className='flex flex-col gap-4 text-white'>
                                    <div className='w-auto flex flex-col items-start gap-y-1'>
                                        <h5 className='text-xl font-semibold flex items-center'>
                                            <Icons
                                                phone
                                                className='mr-2 w-4  h-4 stroke-[2.5]'
                                            />
                                            Call us
                                        </h5>
                                        <div className='ml-6 text-sm flex flex-col gap-y-2'>
                                            <span className=' font-regular'>
                                                Monday to Friday 10:00 AM to
                                                4:00 PM
                                            </span>
                                            <Link
                                                to={linkEmail.path}
                                                className='underline hover:text-primary'
                                            >
                                                {linkEmail.name}
                                            </Link>
                                        </div>
                                    </div>
                                    <br />
                                    <div className='w-auto flex flex-col items-start gap-y-1'>
                                        <h5 className='text-xl font-semibold flex items-center'>
                                            <Icons
                                                sms
                                                className='mr-2 w-4 h-4 stroke-[2.5]'
                                            />
                                            Mail us
                                        </h5>
                                        <div className='flex flex-col gap-y-2 ml-6 text-sm'>
                                            <span className='font-regular'>
                                                Monday to Friday 10:00 AM to
                                                4:00 Pm
                                            </span>
                                            <Link
                                                to={linkPhone.path}
                                                className='underline hover:text-primary'
                                            >
                                                {linkPhone.name}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='lg:w-4/12 md:w-1/2 mt-10 lg:mt-0'>
                        <div className='text-white'>
                            <span className='text-xl font-semibold'>
                                Newsletter
                            </span>
                            <p className='text-sm mt-2 mb-4'>
                                Receive the best offers and personal advice.
                                Always aware of the highest discounts!
                            </p>
                            <form>
                                <Input
                                    email
                                    placeholder='E-mail Address'
                                    styles='w-full'
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
