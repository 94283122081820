import { Text } from 'components/ui';
import { APP_APP_URL } from 'constants/configs';
import { FC } from 'react';

export const PreviewDevice: FC<any> = ({ masterImage, name, description }) => {
    return (
        <div className='flex items-center bg-white md:bg-transparent rounded-[16px] lg:rounded-30px p-5 mb-5 md:flex-col'>
            <div className='w-28 border-r pr-5 md:w-2/5 md:border-0 md:p-0'>
                <img
                    className='block w-full'
                    src={APP_APP_URL + masterImage}
                    alt=''
                />
            </div>
            <div className='w-full text-center md:mt-5'>
                <Text h1>{name}</Text>
                <Text h3 styles='!font-regular md:mt-1'>
                    {description}
                </Text>
            </div>
        </div>
    );
};
