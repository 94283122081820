import { APP_APP_URL } from 'constants/configs';
import { useCategoriesByClientView, useProductSp } from 'hooks/api';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { Icons } from './Icons';

export const Submenu: FC<any> = () => {
    const [level1, setLevel1] = useState<number>(0);
    const [level2, setLevel2] = useState<number>(0);
    const [level3, setLevel3] = useState<number>(0);

    const [selectedId, setSelectedId] = useState<any>('');

    const { data: categories } = useCategoriesByClientView(1);
    const { data: devices } = useProductSp(selectedId);

    return (
        <div className='mega-menu duration bg-white rounded-30px overflow-hidden shadow-box absolute -left-[50px] lg:-left-[100px] top-[50px] 2xl:w-[900px] md:w-[650px] lg:w-[750px] h-96 z-50 hidden md:flex'>
            <ul className='py-9 flex flex-col gap-y-3 relative'>
                {categories?.data?.map((category: any, indexCategory: any) => {
                    return (
                        <li
                            key={indexCategory}
                            onMouseEnter={() => {
                                if (
                                    category.children?.length !== 0 &&
                                    category.children?.length !== undefined
                                ) {
                                    setLevel1(indexCategory);
                                } else {
                                    setLevel1(10);
                                }
                            }}
                            onMouseLeave={() => {
                                setLevel1(10);
                            }}
                        >
                            <Link
                                to='#'
                                className={`pl-5 lg:pl-11 pr-2 hover:text-primary flex justify-between items-center text-sm xl:text-base min-w-[140px] lg:min-w-[170px] hover:text-hover ${
                                    level1 === indexCategory && 'text-primary'
                                }`}
                            >
                                {category.name}
                                {level1 === indexCategory && (
                                    <Icons
                                        arrowRight
                                        className='w-[20px] h-[20px]'
                                    />
                                )}
                            </Link>
                            {level1 === indexCategory && (
                                <ul className='absolute left-full top-0 h-full w-full py-9 bg-gray-light flex flex-col gap-y-3'>
                                    {category.children
                                        ?.slice(0, 10)
                                        ?.map(
                                            (
                                                company: any,
                                                indexDevice: any,
                                            ) => {
                                                return (
                                                    <li
                                                        key={indexDevice}
                                                        onMouseEnter={() => {
                                                            if (
                                                                company.devices
                                                                    ?.length !==
                                                                0
                                                            ) {
                                                                setLevel2(
                                                                    indexDevice,
                                                                );
                                                                setSelectedId(
                                                                    company.id,
                                                                );
                                                            } else {
                                                                setLevel2(15);
                                                            }
                                                        }}
                                                        onMouseLeave={() => {
                                                            setLevel2(15);
                                                        }}
                                                    >
                                                        <Link
                                                            to='#'
                                                            className={`pl-5 lg:pl-11 pr-2 flex justify-between items-center hover:text-primary text-sm  ${
                                                                level2 ===
                                                                    indexDevice &&
                                                                'text-primary'
                                                            }`}
                                                        >
                                                            {company.name}
                                                            {level2 ===
                                                                indexDevice && (
                                                                <Icons
                                                                    arrowRight
                                                                    className='w-[20px] h-[20px]'
                                                                />
                                                            )}
                                                        </Link>

                                                        {level2 ===
                                                            indexDevice && (
                                                            <ul className='absolute left-full top-0 h-full py-9 flex flex-col gap-y-3 w-[170px] xl:w-[200px]'>
                                                                {devices?.data?.data?.map(
                                                                    (
                                                                        device: any,
                                                                        index: any,
                                                                    ) => {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    index
                                                                                }
                                                                                onMouseEnter={() => {
                                                                                    if (
                                                                                        device
                                                                                            .image
                                                                                            ?.length !==
                                                                                        0
                                                                                    ) {
                                                                                        setLevel3(
                                                                                            index,
                                                                                        );
                                                                                    } else {
                                                                                        setLevel3(
                                                                                            10,
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                onMouseLeave={() => {
                                                                                    setLevel3(
                                                                                        10,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <Link
                                                                                    to={`/appointment/repairation/${device.id}`}
                                                                                    className={`pl-5 lg:pl-11 pr-2 text-sm flex justify-between items-center w-full hover:text-primary ${
                                                                                        level3 ===
                                                                                            index &&
                                                                                        'text-primary'
                                                                                    }`}
                                                                                >
                                                                                    {
                                                                                        device.name
                                                                                    }
                                                                                    {level3 ===
                                                                                        index && (
                                                                                        <Icons
                                                                                            arrowRight
                                                                                            className='w-[20px] h-[20px]'
                                                                                        />
                                                                                    )}
                                                                                </Link>
                                                                                {level3 ===
                                                                                    index && (
                                                                                    <div className='absolute left-full content-center top-0 h-full py-9 flex gap-y-3 w-full items-center justify-center text-center'>
                                                                                        <Link
                                                                                            to='#'
                                                                                            className='w-2/3'
                                                                                        >
                                                                                            <img
                                                                                                src={
                                                                                                    APP_APP_URL +
                                                                                                    device.masterImage
                                                                                                }
                                                                                                alt=''
                                                                                            />
                                                                                            <span className='mt-5 block font-bold'>
                                                                                                {
                                                                                                    device.name
                                                                                                }
                                                                                            </span>
                                                                                        </Link>
                                                                                    </div>
                                                                                )}
                                                                            </li>
                                                                        );
                                                                    },
                                                                )}
                                                            </ul>
                                                        )}
                                                    </li>
                                                );
                                            },
                                        )}
                                </ul>
                            )}
                        </li>
                    );
                })}
                <Link
                    to='/selectDevice'
                    className='mt-auto text-primary pl-11 text-sm 2xl:text-base'
                >
                    All Products
                </Link>
            </ul>
        </div>
    );
};
