import axios from 'axios';
import { APP_API_URL } from 'constants/configs';

const token = localStorage.getItem('token');

const config = token
    ? {
          baseURL: APP_API_URL,
          headers: { Authorization: `Bearer ${token}`, tenant: 'root' },
      }
    : {
          baseURL: APP_API_URL,
      };

const api = axios.create(config);

const { get, post, delete: remove, put } = api;

export { get, post, remove, put };
