import { get, post, put } from 'config/api';
import { useQuery, useMutation } from 'react-query';

const profile = {
    personal: () => get('/v1/personal/profile').then(res => res.data),
    repair: (type?: any, status?: any) =>
        post('/v1/personal/repairrequests', {
            type: type,
            status: status,
        }).then(res => res.data),

    repairById: (id?: number) =>
        get(`/v1/personal/repairrequest/${id}`).then(res => res.data),
    followUp: (trackingCode?: any, email?: any) =>
        post(`/v1/repairrequests/followup`, {
            trackingCode: trackingCode,
            email: email,
        }).then(res => res.data),
};

export const useProfile = () => {
    return useQuery(['personal'], () => profile.personal());
};
export const useRepair = (type: number, status: number) => {
    return useQuery(['repair', type, status], () =>
        profile.repair(type, status),
    );
};

export const useRepairById = (id?: any) => {
    return useQuery(['repairById', id], () => profile.repairById(id), {
        enabled: !!id,
    });
};

export const useFollowUp = (trackingCode: any, email: any) => {
    return useQuery(
        ['followUp', trackingCode, email],
        () => profile.followUp(trackingCode, email),
        { enabled: !!trackingCode && !!email },
    );
};
