import { Icons } from 'components/ui';
import { FC } from 'react';

export const Brief: FC = () => {
    return (
        <ul className='container xl:px-10 hidden lg:flex items-center text-[13px] my-5'>
            <li className='mr-10 flex items-center'>
                <Icons tickCircle className='w-5 mr-1' />
                <strong className='font-semibold'>
                    <span className='text-secondary'>Orginal </span>
                    <span>parts</span>
                </strong>
            </li>
            <li className='mr-10 flex items-center'>
                <Icons tickCircle className='w-5 mr-1' />
                <strong className='font-semibold'>
                    <span className='text-secondary'>Authorized </span>
                    <span>Apple Repairer</span>
                </strong>
            </li>

            <li className='mr-10 flex items-center'>
                <Icons tickCircle className='w-5 mr-1' />
                <strong className='font-semibold'>
                    <span className='text-secondary'>Regardless</span>
                    <span> of where you bought it</span>
                </strong>
            </li>

            <li className='mr-10 flex items-center'>
                <Icons tickCircle className='w-5 mr-1' />
                <strong className='font-semibold'>
                    <span>Repaired in </span>
                    <span className='text-secondary'>no time</span>
                </strong>
            </li>

            <li className='mr-10 flex items-center'>
                <Icons tickCircle className='w-5 mr-1' />
                <strong className='font-semibold'>
                    <span>By </span>
                    <span className='text-secondary'>appointment </span>
                    <span>and </span>
                    <span className='text-secondary'>send</span>
                </strong>
            </li>
        </ul>
    );
};
