import { Text } from 'components/ui';
import { FC } from 'react';

interface RepairTimeProps {
    col?: boolean;
}
export const RepairTime: FC<RepairTimeProps> = ({ children, col }) => {
    return (
        <div className='border-b-2'>
            <div
                className={`flex ${
                    col ? 'flex-col gap-y-1' : ''
                } justify-between py-5`}
            >
                <Text h3>Repair time</Text>
                {children}
            </div>
        </div>
    );
};
