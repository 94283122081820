import { FC, InputHTMLAttributes } from 'react';
import { Icon } from '@iconify/react';

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
    textarea?: boolean;
    email?: boolean;
    input?: boolean;
    rows?: number;
    icon?: any;
    iconImg?: string;
    styles?: string;
}

export const Input: FC<IInput> = ({
    textarea,
    email,
    input,
    rows,
    styles,
    placeholder,
    icon,
    iconImg,
    disabled,
    ...props
}) => {
    return (
        <>
            {textarea ? (
                <div
                    className={`relative overflow-hidden rounded-20px flex items-center bg-white ${styles}`}
                >
                    <textarea
                        rows={rows}
                        className={`px-6 py-4 text-dark-color bg-white text-sm focus:outline-0 font-regular w-full border-0`}
                        {...(props as any)}
                        placeholder={placeholder}
                    />
                </div>
            ) : (
                ''
            )}

            {input ? (
                <div
                    className={`relative overflow-hidden flex items-center ${
                        icon ? 'pr-6' : ''
                    } bg-white rounded-30px ${styles}`}
                >
                    <input
                        className={`px-6 py-3 text-dark-color bg-input-color border-0 text-sm focus:outline-0 focus:ring-0 font-regular w-full placeholder:text-light-color`}
                        placeholder={placeholder ? placeholder : 'Search'}
                        {...props}
                    />
                    {icon ? icon : ''}
                </div>
            ) : (
                ''
            )}
            {email ? (
                <div
                    className={`relative overflow-hidden flex items-center bg-white h-[46px] rounded-30px ${styles}`}
                >
                    <input
                        className={`px-6 text-dark-color bg-white text-base focus:outline-0 focus:ring-0 border-0 font-medium w-full`}
                        placeholder={placeholder ? placeholder : 'Search'}
                        {...props}
                    />
                    <button
                        type='submit'
                        className='absolute right-0 w-[50px] h-full rounded-30px bg-primary border-0 focus:ring-0 text-white flex justify-center items-center'
                    >
                        <Icon icon='fontisto:angle-right' />
                    </button>
                </div>
            ) : (
                ''
            )}
        </>
    );
};
