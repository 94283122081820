import { Text } from 'components/ui';
import { FC } from 'react';

export const Overview: FC = ({ children }) => {
    return (
        <div className='border-b-2 pb-3'>
            <Text h2>Registration overview</Text>
            <Text h3 styles='mt-3'>
                Selected device
            </Text>
            <Text p styles='mb-5 mt-1 !text-sm'>
                {children}
            </Text>
        </div>
    );
};
