import React, { FC, HTMLAttributes } from 'react';

interface IRadio extends HTMLAttributes<HTMLInputElement> {
    cn?: string;
    name?: string;
    value?: string;
    checked?: boolean;
    lg?: boolean;
    styles?: string;
}

export const Radio: FC<IRadio> = ({
    cn,
    lg,
    name,
    value,
    checked,
    styles,
    ...props
}) => {
    cn = `border-0 ring-2 ring-[#DAEAFF] checked:bg-none checked:ring-primary ${
        lg
            ? 'w-[10px] h-[10px] xl:w-[18px] xl:h-[18px] ring-offset-4 checked:ring-offset-4'
            : 'w-[10px] h-[10px] ring-offset-2 checked:ring-offset-2'
    } ${styles}`;

    return (
        <input
            type='radio'
            className={cn}
            {...props}
            name={name}
            value={value}
            checked={checked}
        />
    );
};
