import { Button, Icons, Text } from 'components/ui';
import { FC } from 'react';

export const Conversation: FC = () => {
    return (
        <div className='bg-gray-light border rounded-[16px] lg:rounded-30px py-10 px-5 text-center md:text-left md:px-16'>
            <div>
                <Text h2>
                    Do you need help or do you have another question?
                </Text>
                <Text p styles='mt-3'>
                    We are happy to help you further.
                </Text>
            </div>
            <Button
                btnType='primary'
                paddingBig
                styles='md:ml-auto md:mr-0 mx-auto mt-5 md:mt-0'
            >
                <Icons massage className='hidden md:block' />
                <span>Start a converstion</span>
            </Button>
        </div>
    );
};
