import { Button, Icons, Input, Radio, Text } from 'components/ui';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useModal, useStoreAppointment } from 'hooks';
import {
    useAddresses,
    useCreateAddress,
    useDeleteAddress,
    useUpdateAddress,
} from 'hooks/api';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Item } from 'pages/TermConditions';

export const Biling = () => {
    const { Modal, setIsOpen, editable, setEditable } = useModal({
        title: 'Build new information',
    });
    const [ut, setUt] = useState(0);
    const [id, setId] = useState<any>(null);

    const { data: addresses } = useAddresses();
    const deleteAddress = useDeleteAddress();
    const createAddress = useCreateAddress();
    const updateAddress = useUpdateAddress();

    const addressDeleteHandler = (id: any) => {
        if (!window.confirm('Are you sure you want to delete this item ?'))
            return;
        toast.promise(deleteAddress.mutateAsync(id), {
            pending: 'Please wait...',
            success: 'Deleted successfully',
            error: 'Something went wrong',
        });
    };

    return (
        <>
            <Modal className='max-w-xl'>
                <div className='mb-5 text-base'>
                    <Text p className='mr-8'>
                        I am a
                    </Text>
                    <div className='mt-3'>
                        <label className='mr-10'>
                            <Radio
                                name='information'
                                styles='mr-3'
                                onChange={() => setUt(0)}
                                checked={ut === 0}
                            />
                            person
                        </label>
                        <label>
                            <Radio
                                name='information'
                                styles='mr-3'
                                onChange={() => setUt(1)}
                                checked={ut === 1}
                            />
                            Cammercial
                        </label>
                    </div>
                </div>
                <Formik
                    initialValues={{
                        fullName: editable?.fullName ? editable?.fullName : '',
                        phoneNumber: editable?.phoneNumber
                            ? editable?.phoneNumber
                            : '',
                        city: editable?.city ? editable?.city : '',
                        zipCode: editable?.zipCode ? editable?.zipCode : '',
                        address: editable?.address ? editable?.address : '',
                        email: '',
                        companyName: '',
                        companyNumber: '',
                        vatNumber: '',
                    }}
                    onSubmit={async (values: any) => {
                        if (localStorage.getItem('token')) {
                            if (editable) {
                                const arr: any = {
                                    address: values?.address,
                                    city: values?.city,
                                    fullName: values?.fullName,
                                    phoneNumber: values?.phoneNumber,
                                    zipCode: values?.zipCode,
                                };
                                console.log(arr);
                                updateAddress
                                    .mutateAsync(editable?.id, arr)
                                    .then(() => {
                                        setIsOpen(false);
                                    });
                            } else {
                                createAddress.mutateAsync(values).then(() => {
                                    setIsOpen(false);
                                });
                            }
                        } else {
                            setIsOpen(false);
                        }
                    }}
                    validationSchema={Yup.object().shape({
                        fullName: Yup.string().required('Required'),
                        city: Yup.string().required('Required'),
                        zipCode: Yup.number().required('Required'),
                        address: Yup.string().required('Required'),
                        email: !editable
                            ? Yup.string().email().required('Required')
                            : Yup.string(),
                        phoneNumber: Yup.number().required('Required').min(8),
                        companyName: Yup.string(),
                        companyNumber: Yup.string(),
                        vatNumber: Yup.number(),
                    })}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                        } = props;
                        return (
                            <form onSubmit={handleSubmit} className='space-y-5'>
                                <div>
                                    <Input
                                        input
                                        placeholder='Full Name'
                                        name='fullName'
                                        value={values.fullName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        styles={
                                            errors.fullName && touched.fullName
                                                ? 'text-red-400 border border-red-300'
                                                : ''
                                        }
                                    />
                                    {errors.fullName && touched.fullName && (
                                        <div className='text-red-400 pl-4'>
                                            {errors.fullName}
                                        </div>
                                    )}
                                </div>
                                <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
                                    <div>
                                        <Input
                                            input
                                            placeholder='Email'
                                            name='email'
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            styles={
                                                errors.email && touched.email
                                                    ? 'text-red-400 border border-red-300'
                                                    : ''
                                            }
                                        />
                                        {errors.email && touched.email && (
                                            <div className='text-red-400 pl-4'>
                                                {errors.email}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <Input
                                            input
                                            placeholder='phone'
                                            name='phoneNumber'
                                            value={values.phoneNumber}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            styles={
                                                errors.phoneNumber &&
                                                touched.phoneNumber
                                                    ? 'text-red-400 border border-red-300'
                                                    : ''
                                            }
                                        />
                                        {errors.phoneNumber &&
                                            touched.phoneNumber && (
                                                <div className='text-red-400 pl-4'>
                                                    {errors.phoneNumber}
                                                </div>
                                            )}
                                    </div>
                                    <div>
                                        <Input
                                            input
                                            placeholder='city'
                                            name='city'
                                            value={values.city}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            styles={
                                                errors.city && touched.city
                                                    ? 'text-red-400 border border-red-300'
                                                    : ''
                                            }
                                        />
                                        {errors.city && touched.city && (
                                            <div className='text-red-400 pl-4'>
                                                {errors.city}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <Input
                                            input
                                            placeholder='postal code'
                                            name='zipCode'
                                            value={values.zipCode}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            styles={
                                                errors.zipCode &&
                                                touched.zipCode
                                                    ? 'text-red-400 border border-red-300'
                                                    : ''
                                            }
                                        />
                                        {errors.zipCode && touched.zipCode && (
                                            <div className='text-red-400 pl-4'>
                                                {errors.zipCode}
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-span-full'>
                                        <Input
                                            input
                                            placeholder='street and house number'
                                            name='address'
                                            styles={
                                                errors.address &&
                                                touched.address
                                                    ? 'text-red-400 border border-red-300'
                                                    : ''
                                            }
                                            value={values.address}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.address && touched.address && (
                                            <div className='text-red-400 pl-4'>
                                                {errors.address}
                                            </div>
                                        )}
                                    </div>
                                    <Input
                                        styles={`${
                                            ut === 0
                                                ? 'pointer-events-none opacity-50'
                                                : ''
                                        }`}
                                        required={ut === 1}
                                        input
                                        placeholder='company name'
                                        name='companyName'
                                        value={values.companyName}
                                    />
                                    <Input
                                        styles={`${
                                            ut === 0
                                                ? 'pointer-events-none opacity-50'
                                                : ''
                                        }`}
                                        required={ut === 1}
                                        input
                                        placeholder='company number'
                                        name='companyNumber'
                                        value={values.companyNumber}
                                    />
                                    <Input
                                        styles={`${
                                            ut === 0
                                                ? 'pointer-events-none opacity-50'
                                                : ''
                                        }`}
                                        required={ut === 1}
                                        input
                                        placeholder='vat number'
                                        name='vatNumber'
                                        value={values.vatNumber}
                                    />
                                </div>

                                {editable ? (
                                    <Button
                                        type='submit'
                                        loading={updateAddress.isLoading}
                                        btnType='primary'
                                        styles='w-full'
                                    >
                                        Update
                                    </Button>
                                ) : (
                                    <Button
                                        type='submit'
                                        loading={createAddress.isLoading}
                                        btnType='primary'
                                        styles='w-full'
                                    >
                                        Confirm
                                    </Button>
                                )}
                            </form>
                        );
                    }}
                </Formik>
            </Modal>
            <button
                type='button'
                className='flex justify-center items-center rounded-30px w-full h-[85px] border border-primary text-primary font-semibold bg-gray-light mb-5 hover:opacity-70'
                onClick={() => setIsOpen(true)}
            >
                <Icons
                    add
                    style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '10px',
                    }}
                />
                Build new information
            </button>
            {addresses?.data.map((address: any, index: number) => (
                <div
                    key={address.id}
                    id={`alert-${index}`}
                    role='alert'
                    className='p-5 lg:px-8 bg-white rounded-[16px] lg:rounded-30px mb-4'
                >
                    <div className='flex items-start justify-between w-full'>
                        <div>
                            <Text h4>{address.fullName}</Text>
                            <Text p>{address.address}</Text>
                        </div>
                        <div className='flex items-center'>
                            <button
                                type='button'
                                className='mr-4'
                                onClick={() => {
                                    setIsOpen(true);
                                    setEditable(address);
                                }}
                            >
                                <Icons edit className='w-4 lg:w-5' />
                            </button>
                            <button
                                type='button'
                                onClick={() => {
                                    addressDeleteHandler(address.id);
                                }}
                                className='text-red-400 hover:text-red-800'
                            >
                                <Icons close />
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};
