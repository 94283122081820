import { Button, Text } from 'components/ui';
import { APP_APP_URL } from 'constants/configs';
import { useProfile } from 'hooks/api';
import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

interface DeliveredProps {
    id?: number;
    createRequestDate?: any;
    product?: any;
    repairRequestDetails?: any;
    requestType?: any;
    status?: any;
    trackingCode?: any;
    setIsOpen?: any;
}
export const Delivered: FC<DeliveredProps> = ({
    id,
    createRequestDate,
    product,
    repairRequestDetails,
    requestType,
    status,
    trackingCode,
}) => {
    const { data: personal } = useProfile();
    return (
        <div className='rounded-[16px] lg:rounded-30px bg-white flex gap-x-3 md:gap-x-7 gap-y-5 p-4 md:p-7 mb-5'>
            <div className='w-16 sm:w-24 self-center'>
                <img
                    className='w-full'
                    src={APP_APP_URL + product?.masterImage}
                    alt=''
                />
            </div>
            <div className=' w-full'>
                <Text h3 styles='flex justify-between text-xs'>
                    {product?.name}{' '}
                    <span className='text-gray-400 font-light'>
                        {requestType === 1 ? 'Under repair' : ''}
                        {requestType === 2 ? 'Payment' : ''}
                        {requestType === 3 ? 'Delivered' : ''}
                    </span>
                </Text>
                <Text h3 styles='md:flex items-center mt-4 text-xs'>
                    <b className='xl:text-xl mr-2'>Total</b>
                    <b className='text-secondary xl:text-xl'>
                        {repairRequestDetails?.reduce(
                            (acc: any, curr: any) => acc + curr.price,
                            0,
                        )}
                        ,-
                    </b>
                </Text>

                <Text h3 styles='mt-4 text-xs flex justify-between'>
                    Repair number:
                    <span className='text-gray-400 font-light ml-2'>
                        {trackingCode}
                    </span>
                </Text>
                <Text p styles='text-xs mt-3 flex justify-between'>
                    <span className='text-gray-400'>
                        {dayjs(createRequestDate).format('DD-MM-YYYY')}
                    </span>
                    <span className='text-gray-400 font-light ml-2'>
                        {dayjs(createRequestDate).format('HH:mm')}
                    </span>
                </Text>
                <div className='flex justify-end mt-3'>
                    <Link
                        to={`/followUp/${trackingCode}?id=${id}`}
                        className='text-primary text-xs md:text-sm'
                    >
                        See details
                    </Link>
                </div>
            </div>
        </div>
    );
};
