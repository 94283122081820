import { FC, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Icons, Submenu } from './ui';
import logoSite from 'assets/images/logo.svg';
import { useProfile } from 'hooks/api';
import Logout from 'pages/Logout';

const links = [
    { path: '/selectDevice?type=offerte', name: 'Offerte' },
    { path: '/selectDevice', name: 'Appointment' },
    { path: '/customerService', name: 'Customer service' },
    { path: '/commercial', name: 'Commercial' },
    { path: '/followUp', name: 'Follow up' },
];

const token = localStorage.getItem('token');

const Header: FC = () => {
    return (
        <header className='bg-white'>
            <HeaderMobile />
            <HeaderDesktop />
        </header>
    );
};

export default Header;

const HeaderDesktop: FC<any> = () => {
    return (
        <div className='container py-2 hidden lg:flex'>
            <div className='w-20 flex'>
                <Link to='/'>
                    <img src={logoSite} alt='Logo' />
                </Link>
            </div>
            <ul
                id='primary-menu'
                className='flex flex-col gap-y-4
            md:flex-row relative mx-auto'
            >
                {links.map(link => (
                    <li key={link.name}>
                        <Link
                            to={link.path}
                            onClick={(e: any) => {
                                if (link.name === 'Customer service') {
                                    e.preventDefault();
                                }
                                if (link.name === 'Appointment') {
                                    e.preventDefault();
                                }
                            }}
                            className='px-4 py-3 text-black font-regular hover:text-primary duration-300 inline-block'
                        >
                            {link.name}
                        </Link>
                        {link.name === 'Appointment' && <Submenu />}
                        {link.name === 'Customer service' && (
                            <div className='customerService-menu duration hidden md:flex bg-white rounded-20px min-w-[150px] overflow-hidden shadow-box absolute top-[50px] z-50'>
                                <div className='flex flex-col gap-y-3 py-5'>
                                    <Link
                                        to='/contactUs'
                                        className='px-7 hover:text-primary'
                                    >
                                        Contact us
                                    </Link>
                                    <Link
                                        to='/faq'
                                        className='px-7 hover:text-primary'
                                    >
                                        FAQ
                                    </Link>
                                </div>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
            <div className='flex items-center'>
                {!token ? (
                    <Link to='/login'>
                        <Button btnType='primary' styles='hidden md:block'>
                            sign in / up
                        </Button>
                    </Link>
                ) : (
                    <div className='profile-button relative flex items-center'>
                        <button id='profileMenu' className='mr-3' type='button'>
                            <Icons profileCircle />
                            <div className='absolute z-10 top-12 left-1/2 bg-white -translate-x-1/2 rounded-[16px] shadow-box w-44 py-4 invisible opacity-0 duration-500'>
                                <ProfileMenu />
                            </div>
                        </button>
                    </div>
                )}
                <a href='tel:0307371060' className='z-[2]'>
                    <Button btnType='secondary' styles='ml-4 !hidden xl:!flex'>
                        <Icons phone />
                        030 - 7371060
                    </Button>
                </a>
            </div>
        </div>
    );
};

const HeaderMobile: FC<any> = () => {
    const [closeBtn, setCloseBtn] = useState(<Icons bar />);

    const { pathname, search } = useLocation();

    const handleMneu = () => {
        let menuMobile = document.getElementById('menuMobile');
        if (menuMobile?.classList.contains('-ml-[100%]')) {
            menuMobile?.classList.remove('-ml-[100%]');
            menuMobile?.classList.add('ml-0');
            menuMobile
                .querySelector('.content')
                ?.classList.remove('-ml-[100%]');
            menuMobile.querySelector('.content')?.classList.add('ml-0');
            setCloseBtn(<Icons close />);
        } else {
            menuMobile?.classList.add('-ml-[100%]');
            menuMobile?.classList.remove('ml-0');
            menuMobile?.querySelector('.content')?.classList.add('-ml-[100%]');
            menuMobile?.querySelector('.content')?.classList.remove('ml-0');
            setCloseBtn(<Icons bar />);
        }
    };
    return (
        <div className='container flex justify-between items-center py-3 lg:hidden'>
            <button
                type='button'
                className='border-0 focus:ring-0'
                onClick={handleMneu}
            >
                {closeBtn}
            </button>

            {token ? (
                <div className='profile-button relative flex items-center'>
                    <button id='profileMenu' type='button'>
                        <Icons profileCircle />
                        <div className='absolute z-10 top-12 right-0 bg-white rounded-[16px] shadow-box w-44 py-4 invisible opacity-0 duration-500'>
                            <ProfileMenu />
                        </div>
                    </button>
                </div>
            ) : (
                <Link
                    to='/login'
                    className='bg-primary px-4 py-1 rounded-2xl text-white ml-auto hover:shadow-box-primary duration'
                >
                    Sign in
                </Link>
            )}

            <div
                id='menuMobile'
                className='fixed top-0 bottom-0 left-0 w-full bg-black/30 z-50 -ml-[100%]'
            >
                <div className='content h-full w-10/12 sm:w-8/12 bg-white -ml-[100%] duration-500'>
                    <div className='flex justify-between  px-5 py-2 border-b mb-5'>
                        <div className='w-16 flex'>
                            <Link to='/'>
                                <img src={logoSite} alt='Logo' />
                            </Link>
                        </div>
                        <button
                            type='button'
                            className='border-0 focus:ring-0'
                            onClick={handleMneu}
                        >
                            {closeBtn}
                        </button>
                    </div>
                    <ul className='flex flex-col gap-y-4'>
                        <li
                            className={`px-5 ${
                                pathname === '/'
                                    ? 'border-l-4 border-primary'
                                    : 'border-l-4 border-transparent'
                            }`}
                        >
                            <Link to='/'>Home</Link>
                        </li>
                        <li
                            className={`px-5 ${
                                pathname == '/selectDevice' &&
                                search === '?type=offerte'
                                    ? 'border-l-4 border-primary'
                                    : 'border-l-4 border-transparent'
                            }`}
                        >
                            <Link to='/selectDevice?type=offerte'>Offerte</Link>
                        </li>
                        <li
                            className={`px-5 ${
                                pathname === '/selectDevice' &&
                                search !== '?type=offerte'
                                    ? 'border-l-4 border-primary'
                                    : 'border-l-4 border-transparent'
                            }`}
                        >
                            <Link to='/selectDevice'>Appointment</Link>
                        </li>
                        <li
                            className={`px-5 ${
                                pathname === '/contactUs' || pathname === '/faq'
                                    ? 'border-l-4 border-primary'
                                    : 'border-l-4 border-transparent'
                            }`}
                        >
                            <SubLink title='Customer Service' />
                            <div className='overflow-hidden relative max-h-0 duration-300'>
                                <ul className='py-3 px-3 flex flex-col gap-y-2'>
                                    <li>
                                        <Link
                                            to='/contactUs'
                                            className='hover:text-primary duration-300'
                                        >
                                            Contact Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to='/faq'
                                            className='hover:text-primary duration-300'
                                        >
                                            FAQ
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li
                            className={`px-5 ${
                                pathname === '/commercial'
                                    ? 'border-l-4 border-primary'
                                    : 'border-l-4 border-transparent'
                            }`}
                        >
                            <Link
                                to='/commercial'
                                onClick={(e: any) => e.preventDefault()}
                            >
                                Commercial
                            </Link>
                        </li>
                        <li
                            className={`px-5 ${
                                pathname === '/followUp'
                                    ? 'border-l-4 border-primary'
                                    : 'border-l-4 border-transparent'
                            }`}
                        >
                            <Link to='/followUp'>Follow up</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

const ProfileMenu = () => {
    const { data: personal } = useProfile();
    return (
        <ul className='flex flex-col gap-y-3'>
            <li>
                <div className='pl-5 font-regular flex gap-x-3'>
                    <Icons profileCircle className='w-5 h-5 stroke-2' />
                    <div className='flex flex-col text-xs xl:text-sm items-start'>
                        {personal?.firstName}
                        <Link
                            to='/profile'
                            className='text-xs text-blue-500 block font-regular relative hover:opacity-70 duration-300'
                        >
                            View Profile
                        </Link>
                    </div>
                </div>
            </li>
            <li>
                <Link
                    to='/profile/appointments'
                    className='group text-xs xl:text-sm flex gap-x-3 pl-5 relative font-regular group hover:text-primary duration-300'
                >
                    <Icons
                        note
                        className='w-5 h-5 group-hover:stroke-primary duration-300'
                    />
                    My repair list
                </Link>
            </li>
            <li>
                <Logout />
            </li>
        </ul>
    );
};

const SubLink: FC<any> = ({ title }) => {
    return (
        <button
            type='button'
            onClick={(e: any) => {
                const icon = e.target?.querySelectorAll('.icon');
                icon[0].classList.toggle('hidden');
                icon[1].classList.toggle('hidden');
                if (e.target.nextSibling.classList.contains('max-h-0')) {
                    e.target.nextSibling.classList.remove('max-h-0');
                    e.target.nextSibling.classList.add('max-h-[500px]');
                } else {
                    e.target.nextSibling.classList.remove('max-h-[500px]');
                    e.target.nextSibling.classList.add('max-h-0');
                }
            }}
            className='flex w-full justify-between items-center relative'
        >
            {title}
            <Icons arrowDown className='icon w-4 h-4' />
            <Icons arrowUp className='icon hidden w-3 h-3 stroke-[#292D32]' />
        </button>
    );
};
