import { FC, Suspense, useEffect } from 'react';
import './styles/app.css';
import { useLocation, useRoutes } from 'react-router-dom';
import routes from 'routes';
import { Loading } from 'components/common';

const App: FC = () => {
    if (
        window.location.pathname !== '/' &&
        window.location.pathname !== '/followUp'
    ) {
        document.body.classList.add('bg-[#F6F6F6]');
    } else {
        document.body.classList.remove('bg-[#F6F6F6]');
    }

    const content = useRoutes(routes);
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return <Suspense fallback={<Loading />}>{content}</Suspense>;
};

export default App;
