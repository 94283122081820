import { Button, CheckBox, Input, Radio, Text } from 'components/ui';
import { useStoreOfferte } from 'hooks';
import { useProduct } from 'hooks/api';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    Container,
    LeftColumn,
    RightColumn,
    Section,
} from '../appointment/content';
import {
    Overview,
    Repairs,
    RepairTime,
    Total,
} from '../appointment/content/rightColumn/index';
import { Toast } from './Toast';
import { Formik } from 'formik';
import * as Yup from 'yup';

export const Register: FC<any> = ({}) => {
    const store = useStoreOfferte(store => store);

    const [checkRead, setCheckRead] = useState(false);
    const { id: productId } = useParams();
    const navigate = useNavigate();

    const { data: product } = useProduct(productId || '');

    useEffect(() => {
        if (store.selectedRepairs.length === 0) {
            navigate('/selectDevice?type=offerte');
        }
        store.setOfferteStep(2);
    }, []);

    return (
        <>
            <Container>
                <Section>
                    {/* left column */}
                    <LeftColumn>
                        <div className='bg-white rounded-20px duration-300 mb-5 p-8'>
                            <Formik
                                initialValues={{
                                    fullName: '',
                                    email: '',
                                }}
                                onSubmit={async (values: any) => {}}
                                validationSchema={Yup.object().shape({
                                    fullName: Yup.string().required('Required'),
                                    email: Yup.string()
                                        .email()
                                        .required('Required'),
                                })}
                            >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        handleChange,
                                        handleBlur,
                                    } = props;
                                    return (
                                        <form className='grid md:grid-cols-2 gap-5'>
                                            <div className='flex items-center mb-5 text-base col-span-full'>
                                                <Text p className='mr-8'>
                                                    I am a
                                                </Text>
                                                <label className='mr-10'>
                                                    <Radio
                                                        name='information'
                                                        styles='mr-3'
                                                        onClick={(e: any) =>
                                                            store.setGender(
                                                                'Mr',
                                                            )
                                                        }
                                                        checked={
                                                            store.gender ===
                                                            'Mr'
                                                        }
                                                    />
                                                    Mr
                                                </label>
                                                <label>
                                                    <Radio
                                                        name='information'
                                                        styles='mr-3'
                                                        onClick={(e: any) =>
                                                            store.setGender(
                                                                'Mrs',
                                                            )
                                                        }
                                                        checked={
                                                            store.gender ===
                                                            'Mrs'
                                                        }
                                                    />
                                                    Mrs
                                                </label>
                                            </div>
                                            <div>
                                                <Input
                                                    input
                                                    placeholder='Full Name'
                                                    name='fullName'
                                                    value={values.fullName}
                                                    onChange={(e: any) => {
                                                        handleChange(e);
                                                        store.setFullName(
                                                            e.target.value,
                                                        );
                                                    }}
                                                    onBlur={handleBlur}
                                                    styles={
                                                        errors.fullName &&
                                                        touched.fullName
                                                            ? 'text-red-500'
                                                            : ''
                                                    }
                                                />
                                                {errors.fullName &&
                                                    touched.fullName && (
                                                        <div className='text-red-500 pl-4'>
                                                            {errors.fullName}
                                                        </div>
                                                    )}
                                            </div>
                                            <div>
                                                <Input
                                                    input
                                                    placeholder='Email'
                                                    name='email'
                                                    value={values.email}
                                                    onChange={(e: any) => {
                                                        handleChange(e);
                                                        store.setEmail(
                                                            e.target.value,
                                                        );
                                                    }}
                                                    onBlur={handleBlur}
                                                    styles={
                                                        errors.email &&
                                                        touched.email
                                                            ? 'text-red-500'
                                                            : ''
                                                    }
                                                />
                                                {errors.email &&
                                                    touched.email && (
                                                        <div className='text-red-500 pl-4'>
                                                            {errors.email}
                                                        </div>
                                                    )}
                                            </div>
                                            <input
                                                type='submit'
                                                id='submit-form'
                                                className='hidden'
                                            />
                                            <div className='flex items-center mt-5 col-span-2'>
                                                <label htmlFor='term'>
                                                    <CheckBox
                                                        required
                                                        id='term'
                                                        onChange={(e: any) =>
                                                            setCheckRead(
                                                                e.target
                                                                    .checked,
                                                            )
                                                        }
                                                    />
                                                </label>
                                                <Text p styles='ml-3'>
                                                    I have read and agree to the
                                                    <a
                                                        href='#'
                                                        className='text-primary mx-1'
                                                    >
                                                        terms and conditions
                                                    </a>
                                                    of the website
                                                </Text>
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </LeftColumn>

                    <RightColumn>
                        <div className='flex flex-col px-6 lg:px-10 py-8 rounded-[16px] lg:rounded-[30px] bg-white'>
                            <Overview>
                                {product?.data?.name} (
                                {product?.data?.description})
                            </Overview>

                            <Repairs>
                                {store.selectedRepairs.length > 0 ? (
                                    store.selectedRepairs.map((item: any) => (
                                        <Toast {...item} />
                                    ))
                                ) : (
                                    <div className='mb-4 text-sm'>
                                        No repairs selected
                                    </div>
                                )}
                            </Repairs>

                            <div className='border-b-2'>
                                <Total col>
                                    <span className='price text-secondary'>
                                        {store.totalPrice}
                                        <span className='currency'>,-</span>
                                    </span>
                                </Total>
                            </div>

                            <RepairTime col>
                                <span>{store.repairTime} minutes</span>
                            </RepairTime>

                            <div className='flex justify-center py-4'>
                                <label htmlFor='submit-form'>
                                    <Link
                                        to={`/offerte/confirmation/${productId}`}
                                        className={`w-full ${
                                            checkRead &&
                                            store.fullName !== '' &&
                                            store.email !== ''
                                                ? ''
                                                : 'opacity-50 pointer-events-none'
                                        }`}
                                    >
                                        <Button
                                            btnType={`${
                                                checkRead &&
                                                store.fullName !== '' &&
                                                store.email !== ''
                                                    ? 'secondary'
                                                    : 'disable'
                                            }`}
                                            paddingBig
                                            styles={`mx-auto`}
                                        >
                                            Next Step
                                        </Button>
                                    </Link>
                                </label>
                            </div>
                        </div>
                    </RightColumn>
                </Section>
            </Container>
            <br />
            <br />
            <br />
            <br />
        </>
    );
};
