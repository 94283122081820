import { Button, Text } from 'components/ui';
import { useNavigate } from 'react-router-dom';

export const Logout = () => {
    const navigate = useNavigate();
    return (
        <div
            id='modale'
            className='fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[rgb(0,0,0,0.31)] z-[9999]'
        >
            <div className='rounded-30px bg-white text-center px-7 py-5'>
                <Text p>Do you want to log out of your account?</Text>
                <div className='flex justify-center gap-x-3 mt-5'>
                    <Button
                        btnType='transparent'
                        onClick={() => {
                            navigate('/profile');
                        }}
                    >
                        No
                    </Button>
                    <Button
                        btnType='primary'
                        onClick={() => {
                            localStorage.removeItem('token');
                            window.location.href = '/';
                        }}
                    >
                        Yes
                    </Button>
                </div>
            </div>
        </div>
    );
};
