import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import * as CONFIGS from 'constants/configs';

interface IDocument {
    title: string;
    description?: string;
}

export const Document: FC<IDocument> = ({ title, description, ...props }) => {
    return (
        <Helmet {...props}>
            <title>
                {title} | {CONFIGS.APP_TITLE}
            </title>
            <meta
                name='description'
                content={description ? description : CONFIGS.APP_DESCRIPTION}
            />
        </Helmet>
    );
};
