import { Button, Icons, PasswordInput, Text } from 'components/ui';
import { put } from 'config/api';
import { useState } from 'react';
import { toast } from 'react-toastify';

export const ChangePassword = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const form = new FormData(e.target);
        const formData = Object.fromEntries(form.entries());
        setLoading(true);

        try {
            const { data: res } = await put(
                '/v1/personal/change-password',
                formData,
            );
            toast.success('Password changed successfully', {
                position: 'top-center',
            });
            e.target.reset();
        } catch (error: any) {
            console.log(error.response?.data?.exception);
            toast.error(error.response?.data?.exception, {
                position: 'top-center',
            });
        }
        setLoading(false);
    };
    return (
        <div className='rounded-[16px] bg-white rounded-30px px-5 lg:px-14 py-6'>
            <Text h3>Change password</Text>
            <form onSubmit={handleSubmit}>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-10'>
                    <label className='mt-10 block relative'>
                        <PasswordInput
                            label='Current password'
                            placeholder='Enter password'
                            name='password'
                            required
                        />
                    </label>
                </div>
                <div className='mt-8 grid grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-10'>
                    <div className='relative'>
                        <PasswordInput
                            label='New password'
                            placeholder='Enter password'
                            name='newPassword'
                            required
                        />
                    </div>
                    <div className='relative'>
                        <PasswordInput
                            label='Confirm new password'
                            placeholder='Enter password'
                            name='confirmNewPassword'
                            onChange={(e: any) => {
                                const pass: any = document.querySelector(
                                    'input[name="newPassword"]',
                                );

                                if (e.target.value !== pass.value) {
                                    e.target.parentElement?.previousSibling.classList.add(
                                        'text-red-400',
                                    );
                                    setLoading(false);
                                } else {
                                    e.target.parentElement?.previousSibling.classList.remove(
                                        'text-red-400',
                                    );
                                }
                            }}
                            required
                        />
                    </div>
                </div>
                <Button
                    btnType='primary'
                    loading={loading}
                    styles='!px-16 mt-14 ml-auto'
                    type='submit'
                >
                    Change
                </Button>
            </form>
        </div>
    );
};
