import { useEffect } from 'react';
// @ts-ignore
import NProgress from 'react-nprogress';
import 'styles/nprogress.css';

export const Loading = () => {
    // config
    NProgress.configure({ showSpinner: false });

    useEffect(() => {
        NProgress.start();

        return () => {
            NProgress.done();
        };
    });
    return <></>;
};
