import { FC } from 'react';

export const Toast: FC<any> = ({ title, price, repairDuration }) => {
    return (
        <div
            className='p-4 mb-4 text-dark rounded-lg lg:rounded-[20px] shadow bg-gray-light'
            role='alert'
        >
            <div>
                <div>
                    <p className='text-sm 2xl:text-base font-medium'>{title}</p>
                    <div className='mt-3 flex justify-between text-sm'>
                        <span>repair time: {repairDuration} minutes</span>
                        <span className='price'>
                            <span>{price}</span>
                            <span className='currency'>,-</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
