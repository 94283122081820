import {
    FC,
    InputHTMLAttributes,
    useEffect,
    useReducer,
    useState,
} from 'react';
import classNames from 'classnames';
import { Button, Icons, Text } from 'components/ui';
import { Toast } from './Toast';
import { PreviewDevice } from '.';
import { Overview } from './content/rightColumn/Overview';
import {
    Section,
    RightColumn,
    LeftColumn,
    Container,
    Description,
    Conversation,
} from './content';
import { RepairTime } from './content/rightColumn/RepairTime';
import { Repairs, Total, AppleService } from './content/rightColumn/index';
import { Link, useParams } from 'react-router-dom';
import { useProblemByPId, useProduct } from 'hooks/api';
import { useStoreAppointment } from 'hooks';

var accordion = (e: any) => {
    var target = e.target as HTMLButtonElement;
    if (target.parentElement?.classList.contains('max-h-[50px]')) {
        target.children[1].classList.toggle('hidden');
        target.children[2].classList.toggle('hidden');
        target.parentElement?.classList.remove(
            'lg:max-h-[60px]',
            'max-h-[50px]',
        );
        target.parentElement?.classList.add('max-h-[3000px]');
        target.parentElement?.classList.add('shadow-box');
    } else {
        target.children[1].classList.toggle('hidden');
        target.children[2].classList.toggle('hidden');
        target.parentElement?.classList.add('lg:max-h-[60px]', 'max-h-[50px]');
        target.parentElement?.classList.remove('max-h-[3000px]');
        target.parentElement?.classList.remove('shadow-box');
    }
};

interface IAccordionTitle extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
}
export const AccordionTitle: FC<any> = ({ id, children, className }) => {
    return (
        <button
            id={id}
            type='button'
            className={classNames(
                'flex items-center w-full py-3 lg:py-4 px-5 duration-300 text-xs md:text-sm lg:text-base',
                className,
            )}
            onClick={accordion}
        >
            {children}
            <Icons arrowDown className='ml-auto w-4 lg:w-5' />
            <Icons arrowUp className='ml-auto w-4 lg:w-5 hidden' />
        </button>
    );
};

interface IAccordion extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
}
export const Accordion: FC<IAccordion> = ({ children, className }) => {
    return (
        <div
            className={classNames(
                `bg-white rounded-[16px] md:rounded-20px overflow-hidden lg:max-h-[60px] max-h-[50px] duration-300 mb-5`,
                className,
            )}
        >
            {children}
        </div>
    );
};

export const Repairation: FC<any> = () => {
    const [selectedRepairs, setSelectedRepairs] = useState<any>([]);
    const repairItems = selectedRepairs?.filter((item: any) => {
        return item !== undefined;
    });
    const { id: productId } = useParams();

    const { data: product } = useProduct(productId || '');
    const { data: problems } = useProblemByPId(productId || '');

    const store = useStoreAppointment(store => store);

    useEffect(() => {
        store.setAppointmentStep(1);
        store.setRepairTime(
            repairItems.reduce(
                (acc: any, curr: any) => acc + curr.repairDuration,
                0,
            ),
        );
        store.setSelectedDeviceId(productId);
        store.setTotalPrice(
            repairItems.reduce((acc: any, curr: any) => acc + curr.price, 0),
        );
        store.setSelectedRepairs(repairItems);
    }, [selectedRepairs]);

    const [addRemove, setAddRemove] = useState<any>({});
    return (
        <Container>
            <Section>
                <div className='md:hidden w-full'>
                    <PreviewDevice {...product?.data} />
                </div>

                <LeftColumn>
                    {problems?.data.map((problem: any) => (
                        <Accordion key={problem.id}>
                            <AccordionTitle id={problem.id}>
                                <Icons
                                    charge
                                    className='mr-5 lg:mr-7 2xl:mr-10 w-5'
                                />
                                {problem.title}
                            </AccordionTitle>

                            {problem.children.map((item: any) => {
                                // i for change background color item
                                let i: any = 0;

                                return (
                                    <div
                                        key={item?.id}
                                        id={item?.id}
                                        className={`bg-white px-5 lg:px-10 py-5 duration-500`}
                                    >
                                        <Text h3 styles='flex justify-between'>
                                            <span>{item.title}</span>
                                            <span className='price text-secondary'>
                                                {item.price}
                                                {store.currency}
                                            </span>
                                        </Text>
                                        <Text p styles='lg:w-9/12 my-4'>
                                            {item.description}
                                        </Text>
                                        <div className='flex justify-end items-center py-4'>
                                            {selectedRepairs.find(
                                                (x: any) => x?.id === item.id,
                                            ) ? (
                                                <button
                                                    type='button'
                                                    className='w-[108px] h-9 flex items-center justify-center rounded-30px border border-[#FF7575] bg-[#FF7575] text-white duration-300'
                                                    onClick={(e: any) => {
                                                        const problemItem =
                                                            document.getElementById(
                                                                problem.id,
                                                            );
                                                        setSelectedRepairs(
                                                            (prev: any) => [
                                                                prev.filter(
                                                                    (x: any) =>
                                                                        x?.id !==
                                                                        item?.id,
                                                                )[0],
                                                            ],
                                                        );
                                                        document
                                                            .getElementById(
                                                                item?.id,
                                                            )
                                                            ?.classList.remove(
                                                                'bg-gray-light',
                                                            );
                                                        i--;
                                                        if (i === -1) {
                                                            problemItem?.classList.remove(
                                                                'bg-gray-light',
                                                            );
                                                            problemItem?.classList.remove(
                                                                'text-primary',
                                                            );
                                                        }
                                                    }}
                                                >
                                                    Remove
                                                </button>
                                            ) : (
                                                <button
                                                    type='button'
                                                    className='w-[108px] h-9 flex items-center justify-center rounded-30px border border-primary bg-white text-primary duration-300'
                                                    onClick={(e: any) => {
                                                        const problemItem =
                                                            document.getElementById(
                                                                problem.id,
                                                            );
                                                        setSelectedRepairs(
                                                            (prev: any) => [
                                                                ...prev,
                                                                item,
                                                            ],
                                                        );

                                                        document
                                                            .getElementById(
                                                                item?.id,
                                                            )
                                                            ?.classList.add(
                                                                'bg-gray-light',
                                                            );
                                                        i++;
                                                        if (i === 1) {
                                                            problemItem?.classList.add(
                                                                'bg-gray-light',
                                                            );
                                                            problemItem?.classList.add(
                                                                'text-primary',
                                                            );
                                                        }
                                                    }}
                                                >
                                                    Add
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </Accordion>
                    ))}
                </LeftColumn>

                <RightColumn>
                    <div className='hidden md:block'>
                        <PreviewDevice {...product?.data} />
                    </div>
                    <div className='flex flex-col px-6 lg:px-10 py-8 rounded-[16px] lg:rounded-[30px] bg-white'>
                        <Overview>
                            {product?.data?.name} ({product?.data?.description})
                        </Overview>
                        <Repairs>
                            {store.selectedRepairs.length > 0 ? (
                                store.selectedRepairs.map(
                                    (item: any, index: number) => (
                                        <Toast key={index} {...item} />
                                    ),
                                )
                            ) : (
                                <div className='mb-4 text-sm'>
                                    No repairs selected
                                </div>
                            )}
                        </Repairs>

                        <RepairTime>
                            <span>{store.repairTime} minutes</span>
                        </RepairTime>

                        <Total>
                            <span className='price text-secondary'>
                                {store.totalPrice}
                                <span className='currency'>
                                    {store.currency}
                                </span>
                            </span>
                        </Total>
                        <div className='flex flex-col py-4'>
                            <Link
                                to={`/appointment/register/${store.selectedDeviceId}`}
                                className={
                                    store.selectedRepairs.length === 0
                                        ? 'pointer-events-none'
                                        : ''
                                }
                            >
                                <Button
                                    btnType={`${
                                        store.selectedRepairs.length === 0
                                            ? 'disable'
                                            : 'secondary'
                                    }`}
                                    paddingBig
                                    styles={`mx-auto`}
                                >
                                    Request repair
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <AppleService />
                </RightColumn>
            </Section>

            <div className='mb-10'>
                <Conversation />
            </div>

            <Description>
                {[
                    {
                        title: 'iphone 12 pro max',
                        text: `You can leave your Apple iPhone 12 repair to Coolblue
                        without any worries. We can replace your iPhone 12 back,
                        battery, wide-angle lens and more for you. As an Apple
                        Authorized Service point, we always use original parts
                        and if you still have a warranty on the repair, the
                        costs are no longer applicable.`,
                    },
                    {
                        title: 'iPhone 12 pro max screen repair',
                        text: `You can leave your Apple iPhone 12 repair to Coolblue
                    without any worries. We can replace your iPhone 12 back,
                    battery, wide-angle lens and more for you. As an Apple
                    Authorized Service point, we always use original parts
                    and if you still have a warranty on the repair, the
                    costs are no longer applicable.`,
                    },
                ].map((item, index) => (
                    <div key={index}>
                        <Text
                            h2
                            styles={`text-primary ${
                                index !== 0 ? 'mt-12' : ''
                            }`}
                        >
                            {item.title}
                        </Text>
                        <Text p styles='mt-5'>
                            {item.text}
                        </Text>
                    </div>
                ))}
            </Description>
        </Container>
    );
};
