import { Text } from 'components/ui';
import { Stepper } from './Stepper';

import imgRegistration from 'assets/images/appoinment/1.png';
import imgRegister from 'assets/images/appoinment/5.png';
import imgInformation from 'assets/images/appoinment/4.png';
import imgConfirmation from 'assets/images/appoinment/3.png';
import { FC, useState } from 'react';

interface IHeader {
    step?: any;
}
export const Header: FC<IHeader> = ({ step }: IHeader) => {
    const contents = [
        {
            image: imgRegistration,
            title: (
                <>
                    Select <br /> your repairation
                </>
            ),
            description: `Choose the method that suits you best and fill in your
            details. Choose the method that suits`,
        },
        {
            image: imgRegister,
            title: 'Register your repair',
            description: `Choose the method that suits you best and fill in your
            details.`,
        },
        {
            image: imgInformation,
            title: (
                <>
                    Select your <br /> biling information
                </>
            ),
            description: `Choose the method that suits you best and fill in your
            details. Choose the method that suits`,
        },
        {
            image: imgConfirmation,
            title: 'Invoice confirmation',
            description: `Choose the method that suits you best and fill in your
            details. Choose the method that suits`,
        },
    ];
    const contentStep = step - 1;
    return (
        <div className='flex justify-between bg-white rounded-[16px] lg:rounded-30px pl-6 pr-9 md:px-20 py-5'>
            <div className='flex flex-col max-w-[554px] '>
                <Text h1 styles='mt-auto'>
                    {contents[contentStep]?.title}
                </Text>
                <Text h3 styles='pt-5 mb-5'>
                    {contents[contentStep]?.description}
                </Text>
                <Stepper step={step} />
            </div>
            <div className='relative hidden lg:block'>
                <Image image={contents[contentStep]?.image} />
            </div>
        </div>
    );
};

type TImage = {
    image?: any;
};
const Image = ({ image }: TImage) => {
    return (
        <img
            className={`block max-w-[350px] animationOpacity`}
            src={image}
            alt=''
        />
    );
};
