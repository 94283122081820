import { Text } from 'components/ui';
import { FC } from 'react';

export const Repairs: FC<any> = ({ children }) => {
    return (
        <div className='border-b-2'>
            <div>
                <Text h3 styles='my-4'>
                    Selected Repairs
                </Text>
                {children}
            </div>
        </div>
    );
};
