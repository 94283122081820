import { Button, Icons, Input, Text } from 'components/ui';
import { put } from 'config/api';
import { useProfile } from 'hooks/api';
import { FC, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';

export const MyProfile: FC<any> = () => {
    const { data: personal } = useProfile();

    const [editUser, setEditUser] = useState<Boolean>(false);

    const queryClient = useQueryClient();
    const editPersonal = useMutation(
        () => put('/v1/personal/profile', inputState),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('user');
            },
        },
    );

    const [inputState, setInputState] = useState({
        firstName: personal?.firstName,
        lastName: personal?.lastName,
        phoneNumber: '',
        email: personal?.email,
        userName: personal?.userName,
        imageUrl: '',
        companyName: '',
        vatNumber: '',
    });
    useEffect(() => {
        setInputState({
            firstName: personal?.firstName,
            lastName: personal?.lastName,
            phoneNumber: '',
            email: personal?.email,
            userName: personal?.userName,
            imageUrl: '',
            companyName: '',
            vatNumber: '',
        });
    }, [personal?.phoneNumber]);
    const editSubmit = async (e: any) => {
        e.preventDefault();
        if (inputState.firstName === '') {
            toast.error('First name moet worden ingevuld', {
                position: 'top-center',
            });
            return;
        }
        if (inputState.lastName === '') {
            toast.error('Last Name moet worden ingevuld', {
                position: 'top-center',
            });
            return;
        }
        toast.promise(editPersonal.mutateAsync(), {
            pending: 'een ogenblikje a.u.b',
            success: 'succesvol gedaan',
            error: 'Er was een probleem',
        });
        setEditUser(false);
    };

    return (
        <div className=' rounded-[16px] bg-white rounded-30px px-5 lg:px-14 py-6'>
            {editUser ? (
                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                    }}
                    onSubmit={async (values: any) => {
                        values = { ...values, email: inputState?.email };
                        if (inputState.firstName === '') {
                            toast.error('First name moet worden ingevuld', {
                                position: 'top-center',
                            });
                            return;
                        }
                        if (inputState.lastName === '') {
                            toast.error('Last Name moet worden ingevuld', {
                                position: 'top-center',
                            });
                            return;
                        }
                        toast.promise(editPersonal.mutateAsync(), {
                            pending: 'een ogenblikje a.u.b',
                            success: 'succesvol gedaan',
                            error: 'Er was een probleem',
                        });
                        setEditUser(false);
                    }}
                    validationSchema={Yup.object().shape({
                        firstName: Yup.string().required('Required'),
                        lastName: Yup.string().required('Required'),
                    })}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props;
                        return (
                            <form onSubmit={editSubmit}>
                                <Text h1 styles='py-5'>
                                    Edit Profile
                                </Text>
                                <div className='grid grid-cols-1 pag-y-5 md:grid-cols-2 gap-x-7 lg:gap-y-7 '>
                                    <div>
                                        <div className='mb-3'>Firstname</div>
                                        <Input
                                            input
                                            defaultValue={inputState?.firstName}
                                            placeholder='First Name'
                                            name='firstName'
                                            value={
                                                inputState?.firstName
                                                    ? inputState?.firstName
                                                    : values.firstName
                                            }
                                            onChange={(e: any) => {
                                                handleChange(e);
                                                setInputState({
                                                    ...inputState,
                                                    firstName: e.target.value,
                                                });
                                            }}
                                            onBlur={handleBlur}
                                            styles={
                                                errors.firstName &&
                                                touched.firstName
                                                    ? 'text-red-400 border border-red-300'
                                                    : ''
                                            }
                                        />
                                        {errors.firstName &&
                                            touched.firstName && (
                                                <div className='text-red-400 pl-4'>
                                                    {errors.firstName}
                                                </div>
                                            )}
                                    </div>
                                    <div>
                                        <div className='mb-3'>Last Name</div>
                                        <Input
                                            input
                                            defaultValue={inputState?.lastName}
                                            placeholder='First Name'
                                            name='firstName'
                                            value={
                                                inputState?.lastName
                                                    ? inputState?.lastName
                                                    : values.lastName
                                            }
                                            onChange={(e: any) => {
                                                handleChange(e);
                                                setInputState({
                                                    ...inputState,
                                                    firstName: e.target.value,
                                                });
                                            }}
                                            onBlur={handleBlur}
                                            styles={
                                                errors.lastName &&
                                                touched.lastName
                                                    ? 'text-red-400 border border-red-300'
                                                    : ''
                                            }
                                        />
                                        {errors.lastName &&
                                            touched.lastName && (
                                                <div className='text-red-400 pl-4'>
                                                    {errors.lastName}
                                                </div>
                                            )}
                                    </div>
                                    <div className='flex flex-col mt-5'>
                                        <label>Email</label>
                                        <input
                                            disabled
                                            value={inputState?.email}
                                            type='email'
                                            name='email'
                                            className='rounded-20px border-0 bg-input-color text-light-color px-7 py-3 focus:ring-0 mt-3 select-none'
                                        />
                                    </div>
                                </div>
                                <div className='flex gap-x-5 my-10 justify-end'>
                                    <Button
                                        btnType='transparent'
                                        onClick={() => {
                                            setEditUser(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type='submit' btnType='primary'>
                                        Confirm
                                    </Button>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            ) : (
                <div>
                    <Icons
                        profileUser
                        className='w-[40px] h-[40px] lg:w-[60px] lg:h-[60px] stroke-[0.5]'
                    />
                    <ul className='grid grid-cols-1 lg:grid-cols-2'>
                        {[
                            {
                                title: 'First Name',
                                value: inputState?.firstName,
                            },
                            { title: 'Last Name', value: inputState?.lastName },
                            {
                                title: 'E-mail address',
                                value: inputState?.email,
                            },
                        ].map((item, index) => (
                            <li
                                key={index}
                                className='border-b py-3 text-xs md:text-sm lg:text-base'
                            >
                                <span className='mr-3 lg:mr-5 text-light-color'>
                                    {item.title}
                                </span>
                                {item.value}
                            </li>
                        ))}
                    </ul>
                    <button
                        className='text-primary flex items-center gap-x-2 mt-8 hover:opacity-70 text-xs md:text-sm lg:text-base'
                        onClick={() => setEditUser(true)}
                    >
                        <Icons
                            edit
                            className='w-[16px] h-[16px] lg:w-[20px] lg:h-[20px]'
                        />
                        Chane user information
                    </button>
                </div>
            )}
        </div>
    );
};
