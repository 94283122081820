import { FC, HTMLAttributes } from 'react';
import { Icon } from '@iconify/react';

export const SearchInput: FC<any> = ({
    styleInput,
    styles,
    icon,
    placeholder,
    submitHandler,
    ...props
}) => {
    return (
        <div
            className={`relative overflow-hidden h-[50px] 2xl:h-[65px] flex items-center shadow-box bg-white rounded-full border w-full ${styles}`}
        >
            <input
                onChange={submitHandler}
                className={`text-secondary-color border-0 focus:outline-none pr-28 w-full ${
                    styleInput ? styleInput : 'pl-10'
                }`}
                placeholder={placeholder ? placeholder : 'Search'}
                {...props}
            />
            <button
                type='submit'
                className='pointer-events-none absolute right-1 h-[44px] w-[60px] 2xl:h-[59px] 2xl:w-[77px] bg-primary text-white rounded-full flex justify-center items-center'
            >
                {icon ? (
                    <Icon icon={icon} className=' text-2xl' />
                ) : (
                    <Icon icon='eva:search-fill' className=' text-2xl' />
                )}
            </button>
        </div>
    );
};
