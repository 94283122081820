import Footer from 'components/Footer';
import Header from 'components/Header';
import { Document } from './Document';
import { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ILayout {
    title: string;
    description?: string;
}
export const Layout: FC<ILayout> = ({ title, description, children }) => {
    return (
        <>
            <Document title={title} description={description} />
            <Header />
            <ToastContainer />
            <main>{children}</main>
            <Footer />
        </>
    );
};
