import { APP_APP_URL } from 'constants/configs';
import { FC } from 'react';

export const Brand: FC<any> = ({ imageUrl, name }) => {
    return (
        <div className={`flex flex-col items-center justify-center`}>
            <img
                src={APP_APP_URL + imageUrl}
                alt={name}
                className='w-full h-[70px] object-contain'
            />
        </div>
    );
};
