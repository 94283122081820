import { Text } from 'components/ui';
import { FC } from 'react';
interface DateProps {
    col?: boolean;
    changeble?: boolean;
}
export const DateTime: FC<DateProps> = ({ children, col, changeble }) => {
    return (
        <div
            className={`flex ${
                col ? 'flex-col gap-y-1' : ''
            } justify-between py-5`}
        >
            <Text h3>
                date and time
                {changeble && (
                    <a
                        href='#'
                        className='text-primary text-xs ml-2 hover:opacity-70 '
                    >
                        change
                    </a>
                )}
            </Text>
            {children}
        </div>
    );
};
