import { Text } from 'components/ui';
import { FC } from 'react';

interface TotalProps {
    col?: boolean;
}
export const Total: FC<TotalProps> = ({ children, col }) => {
    return (
        <div
            className={`flex ${
                col ? 'flex-col gap-y-1' : ''
            } justify-between py-5`}
        >
            <Text h3>Total</Text>
            {children}
        </div>
    );
};
