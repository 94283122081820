import { Button, Icons, Text } from 'components/ui';
import { Spinner } from 'components/ui/Spinner';
import { post } from 'config/api';
import { useStoreAppointment } from 'hooks';
import { useProduct } from 'hooks/api';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import imgConfirmed from '../../../assets/images/appoinment/2.png';
import { Container, LeftColumn, RightColumn, Section } from './content';
import {
    DateTime,
    Overview,
    Repairs,
    RepairTime,
    Total,
} from './content/rightColumn/index';
import { Toast } from './Toast';

export const Confirmed: FC<any> = ({}) => {
    const [loading, setLoading] = useState(true);
    const { id: productId } = useParams();
    const navigate = useNavigate();

    const { data: product } = useProduct(productId || '');

    const store: any = useStoreAppointment(store => store);

    useEffect(() => {
        if (store.selectedRepairs.length === 0) {
            navigate('/selectDevice');
        }
    }, []);

    let url = `/v1/repairrequests/${
        localStorage.getItem('token') ? 'client' : 'anonymous-client'
    }`;

    const submitHandler = async () => {
        const data = {
            duration: store.repairTime,
            totalPrice: store.totalPrice,
            requestType: 1,
            note: store.note,
            productId: productId,
            fullName: store.selectedBilling.fullName,
            address: store.selectedBilling.address,
            zipCode: store.selectedBilling.zipCode,
            city: store.selectedBilling.city,
            deliveryDate: store.selectedDate,
            phoneNumber: store.selectedBilling.phoneNumber,
            companyName: store.selectedBilling.companyName
                ? store.selectedBilling.companyName
                : null,
            companyNumber: store.selectedBilling.companyNumber
                ? store.selectedBilling.companyNumber
                : null,
            vatNumber: store.selectedBilling.vatNumber
                ? store.selectedBilling.vatNumber
                : null,
            createRequestDate: new Date().toISOString(),
            repairRequestDetails: store.selectedRepairs.map((item: any) => {
                return {
                    price: item.price,
                    problemId: item.id,
                };
            }),
        };
        setLoading(true);
        try {
            const res = await post(url, data);
            console.log(res);
        } catch (err: any) {}
        setLoading(false);
    };

    useEffect(() => {
        submitHandler();
    }, []);

    return (
        <>
            <div className='container mt-5 xl:px-10'>
                <div className='relative bg-white rounded-[16px] lg:rounded-30px p-8 md:py-0'>
                    <div className='flex items-center justify-between'>
                        <div className='flex flex-col justify-center w-full md:w-[90%] md:pl-5 lg:pl-10'>
                            <Text h1 styles=' animationOpacity'>
                                {loading ? (
                                    <>
                                        Please wait....
                                        <br className='hidden md:block' />
                                        <br className='hidden md:block' />
                                    </>
                                ) : (
                                    <>
                                        Your Appointment
                                        <br className='hidden md:block' />
                                        is Confirmed!
                                    </>
                                )}
                            </Text>
                            <div
                                className='rounded-full p-2 xl:p-4 2xl:p-5 mt-5 lg:mt-8 duration-300 '
                                style={{
                                    backgroundColor: loading
                                        ? 'rgb(168 168 168)'
                                        : 'rgba(3, 180, 4, 0.5)',
                                }}
                            >
                                {loading ? (
                                    <div className='text-white'>
                                        <Spinner size='10' />
                                    </div>
                                ) : (
                                    <div
                                        className={`flex items-center justify-start`}
                                    >
                                        <Icons
                                            tickCircle
                                            className='w-10 h-10 lg:w-14 lg:h-14'
                                            style={{
                                                stroke: '#fff',
                                                strokeWidth: 0.7,
                                            }}
                                        />

                                        <Text
                                            p
                                            styles='!text-white ml-3 lg:text-xl xl:text-2xl'
                                        >
                                            Your request has been sent
                                            successfully
                                        </Text>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='w-20 hidden md:block'>
                            <img
                                className='block max-w-[300px] xl:max-w-[400px] 2xl:max-w-[450px] md:-ml-[200px]  xl:-ml-[300px] 2xl:-ml-[350px] animationOpacity'
                                src={imgConfirmed}
                                alt=''
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* infomation device */}
            {loading ? (
                <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </>
            ) : (
                <Container>
                    <Section>
                        {/* left column */}
                        <LeftColumn>
                            <div className='bg-white mb-4 rounded-[30px] md:px-16 px-10 py-8'>
                                <h5 className='text-[24px] font-semibold pb-5 text-primary'>
                                    Your application has been received
                                </h5>
                                <p className='mt-5'>
                                    Within minutes you will receive a
                                    confirmation and the shipping label by
                                    e-mail.
                                </p>
                                <p className='mt-10 mb-5 font-semibold'>
                                    What now?
                                </p>
                                <ul className='list-decimal px-3 flex flex-col gap-y-4'>
                                    <li>
                                        Remove the lock (PIN code, pattern
                                        and/or fingerprint) or write it on the
                                        enclosed registration form.
                                    </li>
                                    <li>
                                        We recommend backing up your phone
                                        before sending.
                                    </li>
                                    <li>
                                        Remove accessories, the SIM and SD card,
                                        so as not to lose them.
                                    </li>
                                    <li>
                                        Pack your phone or tablet well so that
                                        it is not damaged on the way. For
                                        example, use bubble wrap and/or a sturdy
                                        box.
                                    </li>
                                    <li>
                                        Print your{' '}
                                        <a href='#' className='text-primary'>
                                            shipping label
                                        </a>{' '}
                                        and{' '}
                                        <a href='#' className='text-primary'>
                                            the registration
                                        </a>{' '}
                                        form.
                                    </li>
                                    <li>
                                        Put the registration form in the box and
                                        stick the shipping label on the box.
                                    </li>
                                    <li>
                                        Drop off your package at a PostNL point
                                        in your area.
                                    </li>
                                    <li>
                                        Keep your proof of shipment safe. You
                                        can track your package with the Track &
                                        Trace code and our zip code (2501 VE).
                                    </li>
                                </ul>
                            </div>
                        </LeftColumn>

                        {/* right column */}
                        <RightColumn>
                            <div className='flex flex-col px-6 lg:px-10 py-8 rounded-[16px] lg:rounded-[30px] bg-white'>
                                <Overview>
                                    {product?.data?.name} (
                                    {product?.data?.description})
                                </Overview>

                                <Repairs>
                                    {store.selectedRepairs.length > 0 ? (
                                        store.selectedRepairs.map(
                                            (item: any) => <Toast {...item} />,
                                        )
                                    ) : (
                                        <div className='mb-4 text-sm'>
                                            No repairs selected
                                        </div>
                                    )}
                                </Repairs>

                                <div className='border-b-2'>
                                    <Total col>
                                        <span className='price text-secondary'>
                                            <span className='currency'>$</span>
                                            {store.totalPrice}
                                        </span>
                                    </Total>
                                </div>

                                <RepairTime col>
                                    <span>{store.repairTime} minutes</span>
                                </RepairTime>

                                {store.deliveryMode === 1 && (
                                    <DateTime col>
                                        <span>
                                            {new Date(
                                                store.selectedDate,
                                            ).toLocaleString()}
                                        </span>
                                    </DateTime>
                                )}

                                <div className='flex flex-col gap-y-3 items-center mt-10'>
                                    <Button btnType='secondary' styles='w-1/2'>
                                        Print
                                    </Button>
                                    <Button
                                        btnType='primary'
                                        styles='w-1/2 md:w-2/3 lg:w-1/2'
                                    >
                                        Download pdf
                                    </Button>
                                </div>
                            </div>
                        </RightColumn>
                    </Section>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </Container>
            )}
        </>
    );
};
