import { Text } from 'components/ui';
import { Stepper } from './Stepper';

import imgRegistration from 'assets/images/appoinment/1.png';
import imgRegister from 'assets/images/appoinment/5.png';
import imgInformation from 'assets/images/appoinment/4.png';
import imgConfirmation from 'assets/images/appoinment/3.png';
import { FC } from 'react';

interface IHeader {
    step?: Number;
}
export const Header: FC<IHeader> = ({ step }) => {
    var title;
    var description;
    var image;
    switch (step) {
        case 1:
            title = (
                <>
                    Select <br /> your repairation
                </>
            );
            description = (
                <>
                    Choose the method that suits you best and fill in your
                    details. Choose the method that suits
                </>
            );
            image = imgRegistration;
            break;
        case 2:
            title = (
                <>
                    Select <br />
                    offerte information
                </>
            );
            description = (
                <>
                    Choose the method that suits you best and fill in your
                    details. Choose the method that suits
                </>
            );
            image = imgInformation;
            break;
        case 3:
            title = <>Invoice confirmation</>;
            description = (
                <>
                    Choose the method that suits you best and fill in your
                    details. Choose the method that suits
                </>
            );
            image = imgConfirmation;
            break;
    }
    return (
        <>
            <div className='flex justify-between bg-white rounded-[16px] lg:rounded-30px pl-6 pr-9 md:px-20 py-7'>
                <div className='flex flex-col max-w-[554px]'>
                    <Text h1 styles='mt-auto'>
                        {title}
                    </Text>
                    <Text h3 styles='pt-5 '>
                        {description}
                    </Text>
                    <Stepper step={step} />
                </div>
                <div className='relative hidden lg:block'>
                    <img
                        className='block max-w-[350px] 2xl:max-w-[400px] animationOpacity'
                        src={image}
                        alt=''
                    />
                </div>
            </div>
        </>
    );
};
