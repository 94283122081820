import { Button, Text } from 'components/ui';
import { FC } from 'react';
import imgApple from '../../../../../assets/images/apple.svg';

export const AppleService: FC = () => {
    return (
        <div className='flex flex-col text-center justify-center bg-white px-6 md:px-10 py-8 rounded-[16px] lg:rounded-[30px] my-4'>
            <img
                className='w-16 md:w-20 mx-auto mb-8 md:mb-12 md:mt-6'
                src={imgApple}
                alt=''
            />
            <Text h3 styles='mb-4 !text-primary'>
                Authorized Service Provider
            </Text>
            <Text p>
                As an Authorized Apple Service Provider, we can also repair your
                iPhone within warranty. For this we use genuine Apple parts and
                our repairers are extensively trained and certified by Apple.
            </Text>
            <Button btnType='transparent' styles='mx-auto mt-4'>
                read More
            </Button>
        </div>
    );
};
