import { useRepair } from 'hooks/api';
import { Delivered } from './Delivered';
import imgNotExist from 'assets/images/not-exist.svg';

export const Offerte = () => {
    const { data: personalRepair } = useRepair(0, 0);
    return (
        <div className='rounded-[16px] rounded-30px'>
            {personalRepair?.data.length > 0 ? (
                personalRepair?.data.map((item: any, index: number) => (
                    <Delivered key={index} {...item} />
                ))
            ) : (
                <NoItem />
            )}
        </div>
    );
};

const NoItem = () => {
    return (
        <div className='text-center h-full flex flex-col gap-y-5 py-20 justify-center items-center'>
            <img
                className='block w-[130px] xl:w-[170px]'
                src={imgNotExist}
                alt=''
            />
            <span className='font-medium xl:text-lg text-center'>
                there is no item to show
            </span>
        </div>
    );
};
