import * as React from 'react';

interface IStepperProps {
    step?: Number;
}

export const Stepper: React.FunctionComponent<IStepperProps> = ({ step }) => {
    var s1, s2, s3, s4;
    switch (step) {
        case 1:
            s1 = 'active';
            s2 = '';
            s3 = '';
            s4 = '';
            break;
        case 2:
            s1 = 'active done';
            s2 = 'active';
            s3 = '';
            s4 = '';
            break;
        case 3:
            s1 = 'active done';
            s2 = 'active done';
            s3 = 'active';
            s4 = '';
            break;
        case 4:
            s1 = 'active done';
            s2 = 'active done';
            s3 = 'active done';
            s4 = 'active';
            break;
    }

    return (
        <div className='mb-4 lg:mb-16 mt-10 lg:mt-auto'>
            <div
                id='time-line'
                className='flex flex-nowrap w-full lg:w-[550px] xl:w-[650px]'
            >
                <div className={`w-2/5 before:content-["1"] ${s1}`}>
                    <span
                        className={`absolute whitespace-nowrap translate-x-1/2 right-0 mt-6 text-primary-grayfont-regular text-xs lg:text-sm hidden lg:block ${
                            s1 ? 'text-primary' : ''
                        }`}
                    >
                        Select your repairation
                    </span>
                </div>
                <div className={`w-full before:content-["2"] ${s2}`}>
                    <span
                        className={`absolute whitespace-nowrap translate-x-1/2 right-0 mt-6 text-primary-grayfont-regular text-xs lg:text-sm hidden lg:block ${
                            s2 ? 'text-primary' : ''
                        }`}
                    >
                        Register your repair
                    </span>
                </div>
                <div className={`w-full before:content-["3"] ${s3}`}>
                    <span
                        className={`absolute whitespace-nowrap translate-x-1/2 right-0 mt-6 text-primary-grayfont-regular text-xs lg:text-sm hidden lg:block ${
                            s3 ? 'text-primary' : ''
                        }`}
                    >
                        Billing information
                    </span>
                </div>
                <div className={`w-full before:content-["4"] ${s4}`}>
                    <span
                        className={`absolute whitespace-nowrap translate-x-1/2 right-0 mt-6 text-primary-grayfont-regular text-xs lg:text-sm hidden lg:block ${
                            s4 ? 'text-primary' : ''
                        }`}
                    >
                        Invoice confirmation
                    </span>
                </div>
            </div>
        </div>
    );
};
