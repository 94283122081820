import { FC, useEffect, useState } from 'react';
import { Button, Icons, Text } from 'components/ui';
import { Toast } from './Toast';
import { PreviewDevice } from './PreviewDevice';
import {
    Container,
    Conversation,
    Description,
    LeftColumn,
    RightColumn,
    Section,
} from '../appointment/content';
import {
    Overview,
    Repairs,
    RepairTime,
    AppleService,
    Total,
} from '../appointment/content/rightColumn/index';
import { Link, useParams } from 'react-router-dom';
import { useProblemByPId, useProduct } from 'hooks/api';
import { useStoreOfferte } from 'hooks';

export const Repairation: FC<any> = () => {
    const [selectedRepairs, setSelectedRepairs] = useState<any>([]);
    const repairItems = selectedRepairs?.filter((item: any) => {
        return item !== undefined;
    });
    const { id: productId } = useParams();

    const { data: product } = useProduct(productId || '');
    const { data: problems } = useProblemByPId(productId || '');

    const store = useStoreOfferte(store => store);

    useEffect(() => {
        store.setOfferteStep(1);
        store.setRepairTime(
            repairItems.reduce(
                (acc: any, curr: any) => acc + curr.repairDuration,
                0,
            ),
        );
        store.setSelectedDeviceId(productId);
        store.setTotalPrice(
            repairItems.reduce((acc: any, curr: any) => acc + curr.price, 0),
        );
        store.setSelectedRepairs(repairItems);
    }, [selectedRepairs]);

    return (
        <>
            <Container>
                <Section>
                    <div className='md:hidden w-full'>
                        <PreviewDevice {...product?.data} />
                    </div>

                    <LeftColumn>
                        {problems?.data.map(
                            (problem: any, indexItem: number) => (
                                <Accordion key={problem.id}>
                                    <AccordionTitle id={problem.id}>
                                        <Icons
                                            charge
                                            className='mr-5 lg:mr-7 2xl:mr-10 w-5'
                                        />
                                        {problem.title}
                                    </AccordionTitle>

                                    {problem.children.map(
                                        (item: any, indexProblem: any) => {
                                            let i = 0;
                                            return (
                                                <div
                                                    id={item.id}
                                                    className={`bg-white px-5 lg:px-10 py-5 duration-500`}
                                                >
                                                    <Text
                                                        h3
                                                        styles='flex justify-between'
                                                    >
                                                        <span>
                                                            {item.title}
                                                        </span>
                                                        <span className='price text-secondary'>
                                                            {item.price},-
                                                        </span>
                                                    </Text>
                                                    <Text
                                                        p
                                                        styles='lg:w-9/12 my-4'
                                                    >
                                                        {item.description}
                                                    </Text>
                                                    <div className='flex justify-end items-center py-4'>
                                                        {selectedRepairs.find(
                                                            (x: any) =>
                                                                x?.id ===
                                                                item.id,
                                                        ) ? (
                                                            <button
                                                                type='button'
                                                                className='w-[108px] h-9 flex items-center justify-center rounded-30px border border-[#FF7575] bg-[#FF7575] text-white duration-300'
                                                                onClick={(
                                                                    e: any,
                                                                ) => {
                                                                    const problemDIV =
                                                                        document.getElementById(
                                                                            problem.id,
                                                                        );
                                                                    const itemDIV =
                                                                        document.getElementById(
                                                                            item.id,
                                                                        );
                                                                    setSelectedRepairs(
                                                                        (
                                                                            prev: any,
                                                                        ) => [
                                                                            prev.filter(
                                                                                (
                                                                                    x: any,
                                                                                ) =>
                                                                                    x?.id !==
                                                                                    item?.id,
                                                                            )[0],
                                                                        ],
                                                                    );

                                                                    itemDIV?.classList.remove(
                                                                        'bg-gray-light',
                                                                    );
                                                                    i = i - 1;
                                                                    if (
                                                                        i === -1
                                                                    ) {
                                                                        problemDIV?.classList.remove(
                                                                            'bg-gray-light',
                                                                        );
                                                                        problemDIV?.classList.remove(
                                                                            'text-primary',
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                Remove
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type='button'
                                                                className='w-[108px] h-9 flex items-center justify-center rounded-30px border border-primary bg-white text-primary duration-300'
                                                                onClick={(
                                                                    e: any,
                                                                ) => {
                                                                    const problemDIV =
                                                                        document.getElementById(
                                                                            problem.id,
                                                                        );
                                                                    const itemDIV =
                                                                        document.getElementById(
                                                                            item.id,
                                                                        );
                                                                    setSelectedRepairs(
                                                                        (
                                                                            prev: any,
                                                                        ) => [
                                                                            ...prev,
                                                                            item,
                                                                        ],
                                                                    );

                                                                    itemDIV?.classList.add(
                                                                        'bg-gray-light',
                                                                    );
                                                                    i = i + 1;
                                                                    if (
                                                                        i === 1
                                                                    ) {
                                                                        problemDIV?.classList.add(
                                                                            'bg-gray-light',
                                                                        );
                                                                        problemDIV?.classList.add(
                                                                            'text-primary',
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                Add
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        },
                                    )}
                                </Accordion>
                            ),
                        )}
                    </LeftColumn>

                    <RightColumn>
                        <div className='hidden md:block'>
                            <PreviewDevice {...product?.data} />
                        </div>
                        <div className='flex flex-col px-6 lg:px-10 py-8 rounded-[16px] lg:rounded-[30px] bg-white'>
                            <Overview>
                                {product?.data?.name} (
                                {product?.data?.description})
                            </Overview>
                            <Repairs>
                                {store.selectedRepairs.length > 0 ? (
                                    store.selectedRepairs.map((item: any) => (
                                        <Toast {...item} />
                                    ))
                                ) : (
                                    <div className='mb-4 text-sm'>
                                        No repairs selected
                                    </div>
                                )}
                            </Repairs>

                            <RepairTime>
                                <span>{store.repairTime} minutes</span>
                            </RepairTime>

                            <Total>
                                <span className='price text-secondary'>
                                    {store.totalPrice}
                                    <span className='currency'>,-</span>
                                </span>
                            </Total>

                            <div className='flex flex-col py-4'>
                                <Link
                                    to={`/offerte/register/${store.selectedDeviceId}`}
                                    className={
                                        store.selectedRepairs.length === 0
                                            ? 'pointer-events-none'
                                            : ''
                                    }
                                >
                                    <Button
                                        btnType={`${
                                            store.selectedRepairs.length === 0
                                                ? 'disable'
                                                : 'secondary'
                                        }`}
                                        paddingBig
                                        styles={`mx-auto`}
                                    >
                                        Request repair
                                    </Button>
                                </Link>
                            </div>
                        </div>

                        <AppleService />
                    </RightColumn>
                </Section>

                <Conversation />

                <br />
                <br />
                <br />
                <br />
            </Container>
        </>
    );
};

var accordion = (e: any) => {
    var target = e.target as HTMLButtonElement;
    if (target.parentElement?.classList.contains('max-h-[50px]')) {
        target.children[1].classList.toggle('hidden');
        target.children[2].classList.toggle('hidden');
        target.parentElement?.classList.remove(
            'lg:max-h-[60px]',
            'max-h-[50px]',
        );
        target.parentElement?.classList.add('max-h-[3000px]');
        target.parentElement?.classList.add('shadow-box');
    } else {
        target.children[1].classList.toggle('hidden');
        target.children[2].classList.toggle('hidden');
        target.parentElement?.classList.add('lg:max-h-[60px]', 'max-h-[50px]');
        target.parentElement?.classList.remove('max-h-[3000px]');
        target.parentElement?.classList.remove('shadow-box');
    }
};

interface ItemProps {
    priceCurrency?: string;
    price?: string;
    title?: string;
    description?: string;
}
export const Item: FC<ItemProps> = ({
    title,
    description,
    price,
    priceCurrency,
}) => {
    const [action, setAction] = useState('add');
    function handleAdd() {
        setAction('remove');
    }
    function handleRemove() {
        setAction('add');
    }
    return (
        <>
            <div
                className={`${
                    action === 'remove' ? 'bg-gray-light' : 'bg-white'
                } px-5 lg:pl-10 lg:pr-16 py-5 duration-500`}
            >
                <Text h3 styles='flex justify-between'>
                    <span>{title}</span>
                    {action === 'add' && (
                        <span className='price text-secondary'>
                            <span>
                                {price}
                                <span className='currency'>
                                    {priceCurrency}
                                </span>
                            </span>
                        </span>
                    )}
                </Text>
                <Text p styles='lg:w-9/12'>
                    {description}
                </Text>
                <div className='flex justify-end items-center py-4'>
                    {action === 'add' && (
                        <Button btnType='transparent' onClick={handleAdd}>
                            Add
                        </Button>
                    )}
                    {action === 'remove' && (
                        <Button
                            btnType='primary'
                            onClick={handleRemove}
                            styles='bg-[#FF7575] !shadow-red-300'
                        >
                            Remove
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
};

interface AccordionTitleProps {
    title?: string;
    countItem?: number;
    id?: any;
}
export const AccordionTitle: FC<AccordionTitleProps> = ({
    title = 'Display and glasss',
    countItem,
    id,
}) => {
    return (
        <button
            id={id}
            type='button'
            className={`flex items-center w-full py-3 lg:py-4 px-5 duration-300 text-xs md:text-sm lg:text-base`}
            onClick={accordion}
        >
            <Icons charge className='mr-5 lg:mr-10 w-5' />
            {title}
            <Icons arrowDown className='ml-auto w-4 lg:w-5' />
            <Icons arrowUp className='ml-auto w-4 lg:w-5 hidden' />
        </button>
    );
};

interface AccordionProps {}
export const Accordion: FC<AccordionProps> = ({ children }) => {
    return (
        <div
            className={`bg-white rounded-[16px] md:rounded-20px overflow-hidden lg:max-h-[60px] max-h-[50px] duration-300 mb-5`}
        >
            {children}
        </div>
    );
};
