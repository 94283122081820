import create from 'zustand';

export const useStore = create((set: any) => ({
    appointmentStep: 1,
    setAppointmentStep: (step: number) =>
        set((state: any) => ({ ...state, step })),
}));

export const useStoreAppointment = create((set: any) => ({
    appointmentStep: 1,
    selectedDeviceId: '',
    repairTime: 0,
    totalPrice: 0,
    currency: ',-',
    selectedRepairs: [],
    deliveryMode: 0,
    selectedDate: '',
    selectedBilling: '',
    note: 'Do you still need a case or screen protector?  Let us know and we will contact you about the possibilities.',
    setAppointmentStep: (step: number) =>
        set((state: any) => ({ ...state, appointmentStep: step })),
    setSelectedDeviceId: (id: any) =>
        set((state: any) => ({ ...state, selectedDeviceId: id })),
    setRepairTime: (time: any) =>
        set((state: any) => ({ ...state, repairTime: time })),
    setTotalPrice: (total: any) =>
        set((state: any) => ({ ...state, totalPrice: total })),
    setSelectedRepairs: (items: any) =>
        set((state: any) => ({ ...state, selectedRepairs: items })),
    setDeliveryMode: (mode: any) =>
        set((state: any) => ({ ...state, deliveryMode: mode })),
    setSelectedDate: (date: any) =>
        set((state: any) => ({ ...state, selectedDate: date })),
    setSelectedBilling: (item: any) =>
        set((state: any) => ({ ...state, selectedBilling: item })),
    setNote: (note: any) => set((state: any) => ({ ...state, note: note })),
}));

export const useStoreOfferte = create((set: any) => ({
    offerteStep: 1,
    selectedDeviceId: '',
    repairTime: 0,
    totalPrice: 0,
    selectedRepairs: [],
    fullName: '',
    email: '',
    gender: 'Mr',
    setGender: (gn: string) => set((state: any) => ({ ...state, gender: gn })),
    setFullName: (fn: string) =>
        set((state: any) => ({ ...state, fullName: fn })),
    setEmail: (email: string) =>
        set((state: any) => ({ ...state, email: email })),
    setOfferteStep: (step: number) =>
        set((state: any) => ({ ...state, offerteStep: step })),
    setSelectedDeviceId: (id: any) =>
        set((state: any) => ({ ...state, selectedDeviceId: id })),
    setRepairTime: (time: any) =>
        set((state: any) => ({ ...state, repairTime: time })),
    setTotalPrice: (total: any) =>
        set((state: any) => ({ ...state, totalPrice: total })),
    setSelectedRepairs: (items: any) =>
        set((state: any) => ({ ...state, selectedRepairs: items })),
}));
