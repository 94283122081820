import { ReactNode } from 'react';
import {
    ErrorBoundary as ReactErrorBoundary,
    FallbackProps,
} from 'react-error-boundary';

export const ErrorBoundary = (props: { children: ReactNode }): JSX.Element => {
    return (
        <ReactErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the app state
            }}
        >
            {props.children}
        </ReactErrorBoundary>
    );
};

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
    return (
        <div>
            OOPS! something went wrong! <a href='/'>reload page</a>
        </div>
    );
};
