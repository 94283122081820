import { Button, Icons, Text } from 'components/ui';
import { useModal } from 'hooks';
import { FC, ButtonHTMLAttributes } from 'react';

interface LogoutProps extends React.HTMLAttributes<HTMLButtonElement> {
    className?: string;
}
const Logout: FC<any> = ({ className, ...props }: LogoutProps) => {
    const { Modal, setIsOpen } = useModal({
        title: 'Logout',
    });
    return (
        <>
            <button
                type='button'
                className={`flex gap-x-3 ml-5 text-xs xl:text-sm relative font-regular group hover:text-primary duration-300 ${className}`}
                onClick={() => {
                    setIsOpen(true);
                }}
                {...props}
            >
                <Icons
                    logout
                    className='w-5 h-5 group-hover:stroke-primary duration-300'
                />
                Logout
            </button>

            <Modal className='max-w-lg'>
                <div className='rounded-30px bg-white text-center px-5 lg:px-7 py-5'>
                    <Text p>Do you want to log out of your account?</Text>
                    <div className='flex justify-center gap-x-3 mt-5'>
                        <Button
                            type='button'
                            btnType='transparent'
                            onClick={() => setIsOpen(false)}
                        >
                            No
                        </Button>
                        <Button
                            type='button'
                            btnType='primary'
                            onClick={() => {
                                localStorage.removeItem('token');
                                window.location.href = '/';
                            }}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Logout;
