import { get, post } from 'config/api';
import { useQuery, useMutation } from 'react-query';

const products = {
    getAll: (page?: number, size?: number) =>
        post('/v1/products/getproductsbysp', {
            pageNumber: page,
            pageSize: size,
            orderBy: 'name',
        }).then(res => res.data),
    getById: (id: string) =>
        get(`/v1/products/client/${id}`).then(res => res.data),
    getByGroupName: (groupName: string) =>
        get(`/v1/productsections/by-groupname?groupName=${groupName}`).then(
            res => res.data,
        ),
    getBySp: (
        category?: any,
        min?: any,
        max?: any,
        order?: any,
        keyword?: any,
    ) =>
        post('/v1/products/search-by-sp', {
            categoryId: category,
            minPrice: min,
            maxPrice: max,
            orderBy: order,
            keyword: keyword,
        }).then(res => res.data),
};

export const useProducts = (page?: number, size?: number) => {
    return useQuery(['products', page ? page : 'all'], () =>
        products.getAll(page, size),
    );
};

export const useProduct = (id: string) => {
    return useQuery(['products', id], () => products.getById(id), {
        enabled: id !== '',
    });
};

export const useProductSection = (groupName: string) => {
    return useQuery(['products', groupName], () =>
        products.getByGroupName(groupName),
    );
};

export const useProductSp = (
    category?: any,
    min?: any,
    max?: any,
    order?: any,
    keyword?: any,
) => {
    return useQuery(
        ['products-sp', category, min, max, order, keyword],
        () => products.getBySp(category, min, max, order, keyword),
        {
            enabled: category !== '',
        },
    );
};
