import { get, post, put, remove } from 'config/api';
import { useQuery, useMutation, useQueryClient } from 'react-query';

const addresses = {
    getAll: () => get('/v1/personal/addresses').then(res => res.data),
    // getById: (id: string) => get(`/roles/${id}`).then(res => res.data),
    create: (data: any) => post('/v1/personal/address', data),
    delete: (id: string) =>
        remove(`/v1/personal/address/${id}`).then(res => res.data),
    update: (id: any, data: any) =>
        put(`/v1/personal/address/${id}`, data).then(res => res.data),
};

export const useAddresses = () => {
    return useQuery('addresses', addresses.getAll, {
        enabled: localStorage.getItem('token') ? true : false,
    });
};

export const useCreateAddress = () => {
    const queryClient = useQueryClient();

    return useMutation((data: any) => addresses.create(data), {
        onSuccess: () => {
            queryClient.invalidateQueries('addresses');
        },
    });
};

export const useDeleteAddress = () => {
    const queryClient = useQueryClient();

    return useMutation((id: string) => addresses.delete(id), {
        onSuccess: () => {
            queryClient.invalidateQueries('addresses');
        },
    });
};
export const useUpdateAddress = () => {
    const queryClient = useQueryClient();

    return useMutation((id?: any, data?: any) => addresses.update(id, data), {
        onSuccess: () => {
            queryClient.invalidateQueries('addresses');
        },
    });
};
