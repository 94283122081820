import { Button, Text } from 'components/ui';
import { useStoreAppointment } from 'hooks';
import { useProduct } from 'hooks/api';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container, LeftColumn, RightColumn, Section } from './content';
import {
    DateTime,
    Overview,
    Repairs,
    RepairTime,
    Total,
} from './content/rightColumn/index';
import { Toast } from './Toast';

export const Confirmation: FC<any> = props => {
    const { id: productId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const { data: product } = useProduct(productId || '');

    const store: any = useStoreAppointment(store => store);

    useEffect(() => {
        if (store.selectedRepairs.length === 0) {
            navigate('/selectDevice');
        }
        store.setAppointmentStep(4);
    }, []);

    return (
        <>
            {/* infomation device */}
            <Container>
                <Section>
                    <LeftColumn>
                        <div className='bg-white mb-4 rounded-[16px] lg:rounded-30px px-10 py-8'>
                            <p className='xl:text-2xl lg:text-xl text-lg font-semibold mb-5'>
                                Invoice Confirmation
                            </p>
                            <ul className='grid grid-cols-1 md:grid-cols-2'>
                                <li className='border-b py-5 '>
                                    <span className='mr-6 text-[#B9B9B9]'>
                                        FullName
                                    </span>
                                    <span className='block w-full truncate md:pr-10 pt-5'>
                                        {store.selectedBilling.fullName}
                                    </span>
                                </li>
                                <li className='border-b py-5 '>
                                    <span className='mr-6 text-[#B9B9B9]'>
                                        PhoneNumber
                                    </span>
                                    <span className='block w-full truncate md:pr-10 pt-5'>
                                        {store.selectedBilling.phoneNumber}
                                    </span>
                                </li>
                                <li className='border-b py-5 '>
                                    <span className='mr-6 text-[#B9B9B9]'>
                                        City
                                    </span>
                                    <span className='block w-full truncate md:pr-10 pt-5'>
                                        {store.selectedBilling.city}
                                    </span>
                                </li>
                                <li className='border-b py-5 '>
                                    <span className='mr-6 text-[#B9B9B9]'>
                                        Postal Code
                                    </span>
                                    <span className='block w-full truncate md:pr-10 pt-5'>
                                        {store.selectedBilling.zipCode}
                                    </span>
                                </li>
                                <li className='border-b py-5 col-span-full'>
                                    <span className='mr-6 text-[#B9B9B9]'>
                                        Address
                                    </span>
                                    <span className='block w-full truncate md:pr-10 pt-5'>
                                        {store.selectedBilling.address}
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div className='bg-white mb-4 rounded-[16px] lg:rounded-30px px-10 py-8'>
                            <h5 className='xl:text-2xl lg:text-xl text-lg font-semibold lg:pb-5 pb-3 border-b'>
                                Note
                            </h5>
                            <Text p styles='mt-3 lg:mt-5'>
                                {store.note}
                            </Text>
                        </div>
                    </LeftColumn>

                    <RightColumn>
                        <div className='flex flex-col px-6 lg:px-10 py-8 rounded-[16px] lg:rounded-[30px] bg-white'>
                            <Overview>
                                {product?.data?.name} (
                                {product?.data?.description})
                            </Overview>

                            <Repairs>
                                {store.selectedRepairs.length > 0 ? (
                                    store.selectedRepairs.map((item: any) => (
                                        <Toast {...item} />
                                    ))
                                ) : (
                                    <div className='mb-4 text-sm'>
                                        No repairs selected
                                    </div>
                                )}
                            </Repairs>

                            <div className='border-b-2'>
                                <Total col>
                                    <span className='price text-secondary'>
                                        {store.totalPrice}
                                        <span className='currency'>
                                            {store.currency}
                                        </span>
                                    </span>
                                </Total>
                            </div>

                            <RepairTime col>
                                <span>{store.repairTime} minutes</span>
                            </RepairTime>

                            {store.deliveryMode === 1 && (
                                <DateTime col>
                                    <span>
                                        {new Date(
                                            store.selectedDate,
                                        ).toLocaleString()}
                                    </span>
                                </DateTime>
                            )}

                            <div className='flex justify-center py-4'>
                                <Link
                                    to={`/appointment/confirmed/${productId}`}
                                    className='w-full'
                                >
                                    <Button
                                        loading={loading}
                                        paddingBig
                                        btnType='secondary'
                                        styles={`mx-auto`}
                                    >
                                        Confirm
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </RightColumn>
                </Section>
            </Container>
        </>
    );
};
