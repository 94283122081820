import { get, post } from 'config/api';
import { useQuery } from 'react-query';

export const useCategoriesByClientView = (typeNumber: number) => {
    return useQuery('categoriesByClientView', () =>
        get(`/v1/categories/client-view-by-categorytype/${typeNumber}`).then(
            res => res.data,
        ),
    );
};

export const useAllCategories = () => {
    return useQuery('allCategories', () =>
        post('/v1/categories').then(res => res.data),
    );
};

export const useCategoriesByType = (typeNumber: any) => {
    return useQuery('categoriesByType', () => {
        get(`v1/categories/getbycategorytype/${typeNumber}`).then(
            res => res.data,
        );
    });
};
