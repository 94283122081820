import { ButtonHTMLAttributes, FC } from 'react';
import { Spinner } from './Spinner';

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
    btnType?: string;
    paddingBig?: boolean;
    text?: string;
    icon?: string;
    iconImg?: string;
    imgSize?: string;
    styles?: string;
    loading?: boolean;
}

export const Button: FC<IButton> = ({
    text,
    btnType,
    paddingBig,
    icon,
    iconImg,
    imgSize,
    styles,
    children,
    loading,
    ...props
}) => {
    const classes = `relative flex items-center justify-center text-xs lg:text-sm xl:text-base gap-x-2 text-base rounded-full duration-300 py-2 xl:py-3 font-regular select-none z-[2] ${
        paddingBig ? 'px-12' : 'px-6'
    } ${
        btnType === 'primary'
            ? 'bg-primary hover:shadow-box-primary text-white'
            : ''
    } ${
        btnType === 'secondary'
            ? 'bg-secondary hover:shadow-box-secondary text-white'
            : ''
    } ${btnType === 'disable' ? 'bg-[#B9B9B9] text-white' : ''} ${
        btnType === 'transparent'
            ? 'border border-primary text-primary hover:border-transparent hover:text-white hover:bg-primary'
            : ''
    } ${styles} ${loading ? 'pointer-events-none opacity-75' : ''}`;

    return (
        <button className={classes} {...props}>
            <span
                className={
                    loading
                        ? 'opacity-0 flex gap-x-3 text-sm'
                        : 'flex gap-x-3 text-sm'
                }
            >
                {children}
            </span>
            {loading ? (
                <span className='absolute inset-x-0 text-white'>
                    <Spinner />
                </span>
            ) : (
                ''
            )}
        </button>
    );
};
