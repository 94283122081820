import { Button, Radio } from 'components/ui';
import { useStoreAppointment } from 'hooks';
import { useProduct } from 'hooks/api';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container, LeftColumn, RightColumn, Section } from './content';
import {
    Overview,
    Repairs,
    Total,
    RepairTime,
    DateTime,
} from './content/rightColumn/index';
import { Toast } from './Toast';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from 'react-modern-calendar-datepicker';

const times = [
    { id: 1, time: 10, name: '10:00' },
    { id: 2, time: 11, name: '11:00' },
    { id: 3, time: 12, name: '12:00' },
    { id: 4, time: 13, name: '13:00' },
    { id: 5, time: 14, name: '14:00' },
    { id: 6, time: 15, name: '15:00' },
    { id: 7, time: 16, name: '16:00' },
    { id: 8, time: 17, name: '17:00' },
];

export const Register: FC<any> = ({}) => {
    const { id: productId } = useParams();
    const navigate = useNavigate();

    const { data: product } = useProduct(productId || '');

    const store = useStoreAppointment(store => store);

    const [selectedDate, setSelectedDate] = useState({
        day: new Date().getDate(),
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
    });
    const [selectedTime, setSelectedTime] = useState(10);

    useEffect(() => {
        if (store.selectedRepairs.length === 0) {
            navigate('/selectDevice');
        }
        store.setAppointmentStep(2);
        store.setSelectedDate(
            new Date(
                selectedDate.year,
                selectedDate.month - 1,
                selectedDate.day,
                selectedTime,
            ).toISOString(),
        );
    }, [selectedDate, selectedTime]);

    useEffect(() => {
        if (store.deliveryMode === 0) {
            store.setSelectedDate('');
        }
    }, [store.deliveryMode]);

    return (
        <>
            <Container>
                <Section>
                    <LeftColumn>
                        <div className='bg-white w-full rounded-20px overflow-hidden max-h-[69px] duration-300 mb-5'>
                            <label
                                htmlFor='parcel'
                                className='flex items-center w-full h-[69px] px-5 duration-300'
                            >
                                <Radio
                                    id='parcel'
                                    onChange={() => store.setDeliveryMode(0)}
                                    value='0'
                                    checked={store.deliveryMode === 0}
                                    style={{ marginRight: '12px' }}
                                    name='status'
                                />
                                Send by registered parcel:
                            </label>
                        </div>

                        <div
                            className={`bg-white rounded-20px overflow-hidden ${
                                store.deliveryMode === 1 ? '' : 'max-h-[69px]'
                            } duration-300 mb-5`}
                        >
                            <label
                                htmlFor='visitStroe'
                                className='flex items-center h-[69px] w-full px-5 duration-300'
                            >
                                <Radio
                                    id='visitStroe'
                                    onChange={() => store.setDeliveryMode(1)}
                                    style={{ marginRight: '12px' }}
                                    name='status'
                                    value='1'
                                    checked={store.deliveryMode === 1}
                                />
                                Visit us in the store
                            </label>
                            <div className='bg-white px-4 lg:px-10 pt-5 pb-14'>
                                {/* <input
                                    type='datetime-local'
                                    value={selectedDate}
                                    onChange={e =>
                                        setSelectedDate(e.target.value)
                                    }
                                /> */}
                                <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-4'>
                                    <Calendar
                                        value={selectedDate}
                                        onChange={(e: any) =>
                                            setSelectedDate(e)
                                        }
                                        calendarClassName='justify-self-center'
                                        shouldHighlightWeekends
                                        minimumDate={{
                                            day: new Date().getDate(),
                                            month: new Date().getMonth() + 1,
                                            year: new Date().getFullYear(),
                                        }}
                                        colorPrimary='#197bff'
                                    />
                                    <ul className='grid mt-8 lg:mt-0 grid-cols-4 w-full h-fit gap-5 p-8 border rounded-xl border-[lightgray]'>
                                        {times.map(time => (
                                            <li
                                                className={`cursor-pointer rounded-full text-center py-1 ${
                                                    time.time == selectedTime
                                                        ? 'bg-primary text-white'
                                                        : 'bg-primary/10'
                                                }`}
                                                key={time.id}
                                                onClick={() =>
                                                    setSelectedTime(time.time)
                                                }
                                            >
                                                {time.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </LeftColumn>

                    <RightColumn>
                        <div className='flex flex-col px-6 lg:px-10 py-8 rounded-[16px] lg:rounded-[30px] bg-white'>
                            <Overview>
                                {product?.data?.name} (
                                {product?.data?.description})
                            </Overview>

                            <Repairs>
                                {store.selectedRepairs.length > 0 ? (
                                    store.selectedRepairs.map((item: any) => (
                                        <Toast
                                            currency={store.currency}
                                            {...item}
                                        />
                                    ))
                                ) : (
                                    <div className='mb-4 text-sm'>
                                        No repairs selected
                                    </div>
                                )}
                            </Repairs>

                            <div className='border-b-2'>
                                <Total col>
                                    <span className='price text-secondary'>
                                        {store.totalPrice}
                                        <span className='currency'>
                                            {store.currency}
                                        </span>
                                    </span>
                                </Total>
                            </div>

                            <RepairTime col>
                                <span>{store.repairTime} minutes</span>
                            </RepairTime>

                            {store.deliveryMode === 1 && (
                                <DateTime col>
                                    <span>
                                        {new Date(
                                            store.selectedDate,
                                        ).toLocaleString()}
                                    </span>
                                </DateTime>
                            )}

                            <div className='flex justify-center py-4'>
                                <Link
                                    to={`/appointment/information/${productId}`}
                                    className='w-full'
                                >
                                    <Button
                                        btnType='secondary'
                                        paddingBig
                                        styles='mx-auto'
                                    >
                                        Next Step
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </RightColumn>
                </Section>
            </Container>
        </>
    );
};
