import { Fragment, useCallback, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Icons } from 'components/ui';

type ModalProps = {
    title?: string;
    description?: string;
    fullWidth?: boolean;
    closeModal?: any;
};

export const useModal = ({
    title,
    description,
    fullWidth,
    closeModal,
}: ModalProps) => {
    let [isOpen, setIsOpen] = useState(false);
    let [editable, setEditable] = useState<any>(null);

    return {
        Modal: useCallback(
            ({ children, className }: any) => (
                <div className='w-full h-full'>
                    <Transition appear show={isOpen} as={Fragment}>
                        <Dialog
                            as='div'
                            className='fixed inset-0 z-[9999] overflow-y-auto'
                            onClose={() => {
                                setIsOpen(false);
                                setEditable(null);
                            }}
                        >
                            <Transition.Child
                                as={Fragment}
                                enter='ease-out duration-300'
                                enterFrom='opacity-0'
                                enterTo='opacity-100'
                                leave='ease-in duration-200'
                                leaveFrom='opacity-100'
                                leaveTo='opacity-0'
                            >
                                <Dialog.Overlay className='fixed inset-0 bg-black/50 backdrop-blur' />
                            </Transition.Child>

                            <div className='min-h-screen px-4 text-center'>
                                <Transition.Child
                                    as={Fragment}
                                    enter='ease-out duration-300'
                                    enterFrom='opacity-0'
                                    enterTo='opacity-100'
                                    leave='ease-in duration-200'
                                    leaveFrom='opacity-100'
                                    leaveTo='opacity-0'
                                >
                                    <Dialog.Overlay className='fixed inset-0' />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className='inline-block h-screen align-middle'
                                    aria-hidden='true'
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter='ease-out duration-300'
                                    enterFrom='opacity-0 scale-95'
                                    enterTo='opacity-100 scale-100'
                                    leave='ease-in duration-200'
                                    leaveFrom='opacity-100 scale-100'
                                    leaveTo='opacity-0 scale-95'
                                >
                                    <div
                                        className={`inline-block w-full p-6 my-8 align-middle text-left transition-all transform shadow-xl rounded-2xl overflow-hidden ${
                                            className || 'max-w-md'
                                        }`}
                                        style={{ backgroundColor: 'white' }}
                                    >
                                        <div className='flex items-center justify-between mb-8'>
                                            {title && (
                                                <Dialog.Title
                                                    as='h4'
                                                    className='text-base font-medium leading-6 '
                                                >
                                                    {title}
                                                </Dialog.Title>
                                            )}
                                            <button
                                                onClick={() => {
                                                    setIsOpen(false);
                                                    setEditable(null);
                                                }}
                                                className='text-xl focus:outline-none'
                                            >
                                                <Icons close />
                                            </button>
                                        </div>
                                        {description && (
                                            <div className='mt-2'>
                                                <p className='text-15px'>
                                                    {description}
                                                </p>
                                            </div>
                                        )}
                                        <div>
                                            {(title || description) && (
                                                <div className='mt-4' />
                                            )}
                                            {children}
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </div>
            ),
            [isOpen],
        ),
        setIsOpen,
        setEditable,
        editable,
    };
};
