import { FC, InputHTMLAttributes, useState } from 'react';
import classNames from 'classnames';
import { ActionIcon } from './ActionIcon';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';

interface IPasswordInput extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    label?: string;
    error?: string;
}

export const PasswordInput: FC<IPasswordInput> = ({
    className,
    label,
    error,
    ...rest
}) => {
    const [isPassword, setIsPassword] = useState(true);
    return (
        <div className='form-control'>
            {label && (
                <label className='block mb-2 '>
                    <span>{label}</span>
                </label>
            )}
            <div className='relative'>
                <input
                    type={isPassword ? 'password' : 'text'}
                    className={classNames(
                        'w-full bg-input-color rounded-30px focus:ring-0 px-7 py-3 border-0',
                        className,
                    )}
                    {...rest}
                />
                <ActionIcon
                    type='button'
                    className='absolute inset-y-0 right-4 hover:bg-transparent '
                    onClick={() => setIsPassword(!isPassword)}
                >
                    {isPassword ? (
                        <RiEyeLine className='text-xl text-gray-400' />
                    ) : (
                        <RiEyeOffLine className='text-xl text-gray-400' />
                    )}
                </ActionIcon>
            </div>
            {error && (
                <span className='block text-red-500 text-xs mt-3'>{error}</span>
            )}
        </div>
    );
};
