import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import {
    Register,
    Repairation,
    Information,
    Confirmation,
    Confirmed,
} from 'components/pages/appointment';
import {
    Register as ORegister,
    Repairation as ORepairation,
    Information as OInformation,
    Confirmation as OConfirmation,
    Confirmed as OConfirmed,
} from 'components/pages/Offerte';
import {
    MyProfile,
    Appointment as ProfileAppointment,
    Offerte as ProfileOfferte,
    Biling as ProfileBiling,
    ChangePassword as ProfileChangePassword,
    Logout as ProfileLogout,
} from 'components/pages/profile/index';
import { AuthGaurd } from 'components/common';
import Faq from 'pages/Faq';

const Home = lazy(() => import('pages/Home'));
const Offerte = lazy(() => import('pages/Offerte'));
const Appointment = lazy(() => import('pages/Appointment'));
const Follow = lazy(() => import('pages/Follow'));
const FollowUp = lazy(() => import('pages/FollowUp'));
const SelectDevice = lazy(() => import('pages/SelectDevice'));
const Profile = lazy(() => import('pages/Profile'));
const Login = lazy(() => import('pages/Login'));
const SignUp = lazy(() => import('pages/SignUp'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const FAQ = lazy(() => import('pages/Faq'));
const ContactUs = lazy(() => import('pages/ContactUs'));
const TermConditions = lazy(() => import('pages/TermConditions'));

const routes: RouteObject[] = [
    { path: '/', element: <Home /> },
    {
        path: '/offerte',
        element: <Offerte />,
        children: [
            { path: 'repairation/:id', element: <ORepairation /> },
            { path: 'register/:id', element: <ORegister /> },
            { path: 'information/:id', element: <OInformation /> },
            { path: 'confirmation/:id', element: <OConfirmation /> },
            { path: 'confirmed/:id', element: <OConfirmed /> },
        ],
    },
    {
        path: '/appointment',
        element: <Appointment />,
        children: [
            { path: 'repairation/:id', element: <Repairation /> },
            { path: 'register/:id', element: <Register /> },
            { path: 'information/:id', element: <Information /> },
            { path: 'confirmation/:id', element: <Confirmation /> },
            { path: 'confirmed/:id', element: <Confirmed /> },
        ],
    },
    { path: '/followUp', element: <Follow /> },
    { path: '/followUp/:id', element: <FollowUp /> },
    { path: '/selectDevice', element: <SelectDevice /> },
    {
        path: '/profile',
        element: (
            <AuthGaurd>
                <Profile />
            </AuthGaurd>
        ),
        children: [
            { path: '', element: <MyProfile /> },
            { path: 'appointments', element: <ProfileAppointment /> },
            { path: 'offerts', element: <ProfileOfferte /> },
            { path: 'biling', element: <ProfileBiling /> },
            { path: 'change-password', element: <ProfileChangePassword /> },
            { path: 'logout', element: <ProfileLogout /> },
        ],
    },
    { path: '/login', element: <Login /> },
    { path: '/signup', element: <SignUp /> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    { path: '/faq', element: <Faq /> },
    { path: '/contactUs', element: <ContactUs /> },
    { path: '/term-and-conditions', element: <TermConditions /> },
];
export default routes;
